import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/device/local/data.service';

@Component({
  selector: 'app-console-view',
  templateUrl: './console-view.page.html',
  styleUrls: ['./console-view.page.scss'],
})
export class ConsoleViewPage implements OnInit {

  logs = [];
  constructor(private localData: DataService, private modal: ModalController) { }

  ngOnInit() {
    this.logs = this.localData.capturedLogs;
  }

  close() {
    return this.modal.dismiss(null, 'cancel')
  }
}
