
// error-messages.ts
export const ERROR_MESSAGES = {
    1001: "This is the first error.",
    1002: "An error occurred while processing your request.",
    1003: 'By clicking confirm, I want to delete my account and all data associated with it.',
    1004: 'There was a problem uploading your profile Image.',
    // Add more error codes and messages as needed






    // Alert Headers
    2001: 'Upload failed',
    2002: 'Confirm Account Deletion',
    2003: 'Compression Error',
    2004: 'Photo exceeds max size allowed',
    2005: 'The image is too big. The current size is ',
    2006: ' MB and the max size is 2MB. Please try cropping the image smaller',




    // Loading Messages
    3001: 'Updating display name....',
  };


export function getMessage(errorCode: number): string {
    return ERROR_MESSAGES[errorCode] || "An unknown error occurred.";
  }