import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { onAuthStateChanged } from '@firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private auth: Auth, private route: Router) {}
  canActivate2(): Promise<boolean>{
      return new Promise(async (resolve, reject) => {
        onAuthStateChanged(this.auth, async user => {
          if (user) {
            resolve(true);
          } else {
            this.route.navigateByUrl('/', {replaceUrl: true});
            reject(false);
          }
        })
      })
  };

  canActivate(): boolean {
    return this.checkAuth();
  }

  private checkAuth(): boolean {
    var result: boolean;
    onAuthStateChanged(this.auth, async user => {
      if (user) {
        result = true;
      } else {
        this.route.navigateByUrl('/', {replaceUrl: true});
        result = false;
      }
    });
    return result;
  }
  
}
