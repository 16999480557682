import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc, updateDoc } from '@angular/fire/firestore';
import { deleteObject, getBlob, getDownloadURL, ref, Storage, uploadBytes, uploadString } from '@angular/fire/storage';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { AlertController, LoadingController } from '@ionic/angular';
import { DataUrl, NgxImageCompressService, UploadResponse} from 'ngx-image-compress';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Images } from 'src/app/interfaces/interface';
import { DataService } from '../device/local/data.service';
import { DbDataService } from '../firebase/database/db-data.service';
import { Logger } from '../logs/logger.service';

// const fs = require('fs').promises;
// const path = require('path');
// const process = require('process');


// const TOKEN_PATH = path.join(process.cwd(), 'token.json');
//const CREDENTIALS_PATH = path.join(process.cwd(), '/driveCreds.json');
const CREDENTIALS_PATH = ('/driveCreds.json');
const SCOPES = ['https://www.googleapis.com/auth/drive.metadata.readonly'];




const IMAGE_DIR = 'stored-images';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private refreshImages = new BehaviorSubject<string>('false');
  currentStatus = this.refreshImages.asObservable();

  private deleteImgStatus = new BehaviorSubject<string>('false');
  removeImage = this.deleteImgStatus.asObservable();

  imagesTest: Images[] = [];
  type: string;

  base64Str: any;
  kbytes: number;
  bytesBefore: number;
  bytesAfter: number;
  difference: number;
  percentage: number;
  imgResultBeforeCompress: DataUrl = '';
  imgResultAfterCompress: DataUrl = '';
  imgResultAfterResize: DataUrl = '';
  imgResultUpload: DataUrl = '';
  imgResultAfterResizeMax: DataUrl = '';
  imgResultMultiple: UploadResponse[] = [];

  constructor(
    private loading: LoadingController,
    private localData: DataService,
    private compress: NgxImageCompressService,
    private dbData: DbDataService,
    private storage: Storage,
    private firestore: Firestore,
    private alertController: AlertController,
    private imageCompress: NgxImageCompressService,
    private logger: Logger
  ) {}

  private pBlob = new BehaviorSubject<boolean>(false);
  photoBlob = this.pBlob.asObservable();

  public updatePBlob(refresh: boolean) {
    this.pBlob.next(refresh);
  }

  // #region /////////////

  // async authorize() {
  //  const client = await authenticate({
  //     scopes: SCOPES,
  //     keyfilePath: CREDENTIALS_PATH,
  //   });
  //   if (client.credentials) {
  //     console.log('we logged in', client);
  //     // await saveCredentials(client);
  //   }
  //   return client;
  // }
  

  async getDriveKey() {
    const docRef = doc(this.firestore, 'apiKey', 'googleDrive');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const result = docSnap.data();
      // console.debug('checkAppMinVersion data:', docSnap.data());
      return result.key;
    } else {
      // doc.data() will be undefined in this case
      console.debug('Could not find Key for googleDrive');
    }
  }

  async getDriveId() {
    const docRef = doc(this.firestore, 'apiKey', 'googleDrive');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const result = docSnap.data();
      // console.debug('checkAppMinVersion data:', docSnap.data());
      return result.clientId;
    } else {
      // doc.data() will be undefined in this case
      console.debug('Could not find Key for googleDrive');
    }
  }


  //#endregion ///////////

  // #region ///////////// Public Methods that are called /////////////////////

  // Change State of BehaviorSubject to tell page to refresh
  public changeStatus(refresh: string) {
    this.refreshImages.next(refresh);
  }

  public changeDeleteStatus(deleteImg: string) {
    this.deleteImgStatus.next(deleteImg);
  }

  async presentAlert(header, sub, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: sub,
      message: message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  public getImages() {
    console.debug('getImages called: imagesTest returns: ', this.imagesTest);
    return this.imagesTest;
  }

  public deleteImages() {
    this.imagesTest.forEach((file) => {
      this.deleteImage(file);
    });
    console.warn('Files asked to be deleted :', this.imagesTest);
  }

  async deleteImage(file: Images) {
    console.debug(
      'deleteImage function in service started for file: ',
      file.name
    );
    await Filesystem.deleteFile({
      path: file.path,
      directory: Directory.Data,
    }).catch((e) => {
      console.debug(e);
    });
    console.warn('The file was successfully deleted: ', file.name);

    await this.loadFiles();
  }

  public getLocalImages(): Observable<Images[]> {
    const i = of(this.imagesTest);
    console.debug(
      '[ getLocalImages ] what is the value of images :',
      this.imagesTest
    );
    return i as Observable<Images[]>;
  }

  // async getPhotoBlob(id: string, boat: string) {
  //   console.debug('values passed; id: ', id, ' boat: ', boat);
  //   // Create a reference to the file we want to download
  //   const starsRef = ref(this.storage, `boatImgs/${id}/${boat}.png`);

  //   // Get the download URL
  //   getBlob(starsRef)
  //     .then((url) => {
  //       console.debug('Did we get the data for blob? : ', url);
  //       new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onerror = reject;
  //         reader.onload = () => {
  //           this.localData.selections.boatImg = reader.result as string;
  //           //console.debug(this.data.selections.boatImg);
  //           resolve(reader.result);
  //           this.updatePBlob(true);
  //         };
  //         reader.readAsDataURL(url);
  //         const str = reader.result;
  //         //  console.debug('helper: ', reader);
  //       });
  //     })
  //     .catch((error) => {
  //       // A full list of error codes is available at
  //       // https://firebase.google.com/docs/storage/web/handle-errors
  //       switch (error.code) {
  //         case 'storage/object-not-found':
  //           // File doesn't exist
  //           console.debug(error, error.code);
  //           this.updatePBlob(false);
  //           break;
  //         case 'storage/unauthorized':
  //           // User doesn't have permission to access the object
  //           break;
  //         case 'storage/canceled':
  //           // User canceled the upload
  //           break;

  //         // ...

  //         case 'storage/unknown':
  //           // Unknown error occurred, inspect the server response
  //           break;
  //       }
  //     });
  // }

  async getPhotoBlob(id: string, boat: string) {
    console.warn('values passed; id: ', id, ' boat: ', boat);
  
    try {
      // Create a reference to the file we want to download
      const starsRef = ref(this.storage, `clientBoats/${id}.png`);
  
      // Get the download URL
      const url = await getBlob(starsRef);
      console.debug('Did we get the data for blob? : ', url);
      
      const reader = new FileReader();
      reader.readAsDataURL(url);
  
      reader.onload = () => {
       // console.warn(reader.result);
        this.localData.selections.boatImg = reader.result as string;
        this.updatePBlob(true);
      };
      if (url) {
        return url;
      }
    } catch (error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/object-not-found':
          // File doesn't exist
          console.debug(error, error.code);
          this.updatePBlob(false);
          break;
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          console.debug(error, error.code);
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;
  
        // ...
  
        case 'storage/unknown':
          // Unknown error occurred, inspect the server response
          console.debug(error, error.code);
          break;
      }
    }
  }
  

  // #endregion //////////////////////////

  //#region ///////////// GET / UPDATE BOAT PHOTOS /////////////////

  getBoatImgById(id: string, boat: string) {
    const path = `clientBoats/${id}.png`;
    const storageRef = ref(this.storage, path);

    getBlob(storageRef);
    console.warn('db service getblob: ', getBlob);
    return Blob;
  }

  async updateBoatImg(cameraFile: any, client: string, boat?: string) {
    //console.log('camera file = ', cameraFile, 'client =', client)
    // const user = this.auth.currentUser;
    const path = `clientBoats/${client}.png`;
    const storageRef = ref(this.storage, path);
    try {
      if(this.isBlob(cameraFile)) {
        this.blobToDataURL(cameraFile).then(async (dataUrl) => {
          await uploadString(storageRef, dataUrl, 'data_url');
          //console.debug('the uploadString is : ', cameraFile);
          const imageUrl = await getDownloadURL(storageRef);
          console.log('is there image url?', imageUrl);
          const stringUrl = (await getDownloadURL(storageRef)).toString;
          console.debug('is there a string?', stringUrl);
          const clientDocRef = doc(this.firestore, `clients/${client}`);
          await updateDoc(clientDocRef, {
            "boatInfo.photoUrl": imageUrl
          });
          console.debug('we updated the boat img');
          return true;
         })
      } else {
        console.log('Camera File is not Blob', this.isBlob(cameraFile));
      await uploadString(storageRef, cameraFile, 'data_url');
      // console.debug('the uploadString is : ', cameraFile);

      const imageUrl = await getDownloadURL(storageRef);
      const stringUrl = (await getDownloadURL(storageRef)).toString;
      console.debug('is there a string?', stringUrl);
      const clientDocRef = doc(this.firestore, `clients/${client}`);
      await updateDoc(clientDocRef, {
        "boatInfo.photoUrl": imageUrl
      });
      console.debug('we updated the boat img');
      return true;
      }
    } catch (e) {
      console.warn('we didnt update the boat img', e);
      return null;
    }
  }

  async deleteBoatImg(id: string, boat: string) {
    try {
      const path = `boatImgs/${id}.png`;
      const desertRef = ref(this.storage, path);
  
      await deleteObject(desertRef);
      this.presentAlert('Success', '', `The Boat Image for ${boat} has been deleted`);
    } catch (error) {
      this.presentAlert('Error', 'Error in deleting image', error);
    }
  }

  isBlob(value: any): boolean {
    return value instanceof Blob;
  }

  blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to convert Blob to Data URL.'));
        }
      };
  
      reader.onerror = (event) => {
        reject(new Error('Error reading Blob as Data URL: ' + event.target?.error));
      };
  
      reader.readAsDataURL(blob);
    });
  }


  //#endregion ///////////////////////////////////////////////////

  // #region ///////////// Internal Logic //////////////////////////
  logFileSize(file: string) {
    this.bytesAfter = this.imageCompress.byteCount(file);
    this.difference = this.bytesBefore - this.bytesAfter;
    this.percentage =
      ((this.bytesBefore - this.bytesAfter) / this.bytesBefore) * 100;
    const percent = this.percentage.toFixed(2);

    console.debug('Original Size: ', this.bytesBefore / 1000000 + ' MB');
    console.debug('After compression:', this.bytesAfter / 1000000 + ' MB');
    console.debug(
      'File reduced by (MB):',
      this.difference / 1000000 + ' MB or ',
      percent,
      '%'
    );
  }

  buttonId(event) {
    console.debug('buttonId was called to set id for picture');
    console.debug('buttonId target id is: ', event.target.id);
    if (this.type == '') {
      console.warn('TARGET ID IS EMPTY');
    } else {
      this.type = event.target.id;
    }
    console.debug('[buttonId] this.type = ', this.type);
  }

  async processPhoto(value) {
    console.info('processPhoto service has started');
    this.buttonId(value);

    // Show the loading controller
    const start = await this.loading.create({
      message: 'Selecting Photo...',
      cssClass: 'custom-loading',
      duration: 6000,
    });
    await start.present();

    try {
      const image = await Camera.getPhoto({
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
      }).catch((error) => {
          console.warn('Error selecting photo: ', error);
        });
      start.dismiss();

      if (image) {
        console.debug('We got the image now sending to the image compressor');

        // Show loading controller
        const loading = await this.loading.create({
          message: 'Processing Photo...',
          cssClass: 'custom-loading',
        });
        await loading.present();

        this.bytesBefore = this.imageCompress.byteCount(image.dataUrl);

        const ratio = await this.dbData.getImageScale();
        const quality = await this.dbData.getImageQuality();
        const orientation = await this.dbData.getImageOrientation();

        await this.compress.compressFile(image.dataUrl, orientation, ratio, quality)
          .then(async (result: DataUrl) => {
            this.imgResultAfterCompress = result;
            this.logFileSize(result);
            const fileName = this.type + new Date().getTime() + '.jpeg';
            const savedFile = await Filesystem.writeFile({
              path: `${IMAGE_DIR}/${fileName}`,
              data: this.imgResultAfterCompress,
              directory: Directory.Data,
              recursive: true
            });
            this.imagesTest = [];
            console.debug(this.imagesTest);

            Filesystem.readdir({
              path: IMAGE_DIR,
              directory: Directory.Data,
            })
              .then(
                async (result) => {
                  console.log('Starting LoadFileData', result.files);

                  for (let f of result.files) {
                    const filePath = `${IMAGE_DIR}/${f.name}`;
                    console.log('what is file path', filePath);
                    const readFile = await Filesystem.readFile({
                      path: filePath,
                      directory: Directory.Data,
                    });
                    this.imagesTest.push({
                      name: f.name,
                      path: filePath,
                      data: `data:image/jpeg;base64,${readFile.data}`,
                    });
                    console.warn('processPhoto service has completed');
                    console.warn('This is the images: ', this.imagesTest);
                    this.changeStatus('true');
                  }
                },
                async (err) => {
                  console.debug('error in load images: ', err);
                  // Folder does not yet exists!
                  await Filesystem.mkdir({
                    path: IMAGE_DIR,
                    directory: Directory.Data,
                  });
                }
              )
              .then((_) => {});
          }),
          (error: any) => {
            console.error('Compression Error: ', error);
            this.presentAlert('Compression Error', 'Error compressing Image', error);
          };
        loading.dismiss();
      }
    } catch (e) {
      console.warn('There was an error: ', e);
      this.presentAlert('Error','','There was an error selecting image. ' + e);
    }
  }

  async loadFiles() {
    this.imagesTest = [];
    console.debug('LoadFiles was called and started length');
    const load = await this.loading.create({
      message: 'Loading data...',
      cssClass: 'custom-loading',
    });
    await load.present();
    try {

  
      const dirResult = await Filesystem.readdir({
        path: IMAGE_DIR,
        directory: Directory.Data,
      });
  
      console.warn('this is result loadFiles: ', dirResult);
      this.loadFileData(dirResult.files);
    } catch (err) {
      console.debug('error in load images: ', err);
      // Folder does not yet exists!
      await Filesystem.mkdir({
        path: IMAGE_DIR,
        directory: Directory.Data,
      });
    } finally {
      await load.dismiss();
    }
  }

  // Get the actual base64 data of an image
  // base on the name of the file
  async loadFileData(fileNames) {
    console.info('loadFileData Service was called and has started');
    console.warn('fileNames = ', fileNames);

    if (fileNames.length == 0) {
      this.imagesTest = [];
      this.changeDeleteStatus('true');
      console.info('fileNames length was 0');
      console.info('loadFileData Service is done');
      console.info('This is the images: ', this.imagesTest);
    } else {
      for (let f of fileNames) {
        const filePath = `${IMAGE_DIR}/${f.name}`;
        const readFile = await Filesystem.readFile({
          path: filePath,
          directory: Directory.Data,
        });

        // Pushing fields to the Array for other components
        this.imagesTest.push({
          name: f.name,
          path: filePath,
          data: `data:image/jpeg;base64,${readFile.data}`,
        });

        console.info('loadFileData Service is done');
        console.info('This is the images: ', this.imagesTest);
        this.changeDeleteStatus('true');
      }
    }
  }
  // #endregion ///////////////////////////////////////////////////

  //#region  ////////// Updating Boat Images /////////////
  // async updateBoatImg(cameraFile: string, client: string, boat: string) {
  //   // const user = this.auth.currentUser;
  //   const path = `boatImgs/${client}/${boat}.png`;
  //   const storageRef = ref(this.storage, path);
  //   try {
  //     await uploadString(storageRef, cameraFile, 'data_url');
  //     // console.debug('the uploadString is : ', cameraFile);

  //     const imageUrl = await getDownloadURL(storageRef);
  //     const stringUrl = (await getDownloadURL(storageRef)).toString;
  //     console.debug('is there a string?', stringUrl);
  //     const clientDocRef = doc(this.firestore, `clients/${client}`);
  //     await updateDoc(clientDocRef, {
  //       "boatInfo.photoUrl": imageUrl
  //     });
  //     console.debug('we updated the boat img');
  //     return true;
  //   } catch (e) {
  //     console.warn('we didnt update the boat img', e);
  //     return null;
  //   }
  // }

  // async compressImage(img) {
  //   return new Promise(async (resolve, reject) => {
  //     const loading = await this.loading.create({
  //       message: 'Processing Photo...',
  //     });
  //     await loading.present();

  //     const ratio = await this.dbData.getImageScale();
  //     const quality = await this.dbData.getImageQuality();
  //     const orientation = await this.dbData.getImageOrientation();

  //     this.bytesBefore = this.compress.byteCount(img);
  //     this.compress.compressFile(img, orientation, ratio, quality).then(
  //       async (result: DataUrl) => {
  //         this.imgResultAfterCompress = result;

  //           await this.loading.dismiss();
  //           console.debug('did this work?');

  //           this.bytesAfter = this.compress.byteCount(result);
  //           this.difference = this.bytesBefore - this.bytesAfter;
  //           this.percentage = ((this.bytesBefore - this.bytesAfter) / this.bytesBefore) * 100;
  //           const percent = this.percentage.toFixed(2);
  //           // console.debug('Size in bytes after compression is now:', this.bytesAfter + ' bytes');
  //           // console.debug('After compression:', this.bytesAfter / 1000 + ' KB');
  //           // console.debug('After compression:', this.bytesAfter / 1000000 + ' MB');

  //           console.debug('Original Size: ', this.bytesBefore / 1000000 + ' MB');
  //           console.debug('After compression:', this.bytesAfter / 1000000 + ' MB');
  //           console.debug('File reduced by (MB):',this.difference / 1000000 + ' MB or ', percent, '%');
  //           resolve(result);
  //       }).catch((error) => {
  //       reject(error);
  //     });

  async compressImage(img: any) {
    const loading = await this.loading.create({
      message: 'Adding Photo...',
      cssClass: 'custom-loading',
    });
    await loading.present();
  
    try {
      const ratio = await this.dbData.getImageScale();
      const quality = await this.dbData.getImageQuality();
      const orientation = await this.dbData.getImageOrientation();
  
      this.bytesBefore = this.compress.byteCount(img);
      const result = await this.compress.compressFile(img.dataUrl, orientation, ratio, quality);
      console.log(result);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      await this.loading.dismiss();
    }
  }

  async testApi(base64: string) {
    try {
      const img = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Photos,
      });
      console.log(img);
  
      // Show loading controller
      const loading = await this.loading.create({
        message: 'Processing Photo...',
        cssClass: 'custom-loading',
      });
      await loading.present();
  
      this.bytesBefore = this.imageCompress.byteCount(img.dataUrl);
      const result = await this.imageCompress.compressFile(img.dataUrl, 2, 40, 50);
      this.imgResultAfterCompress = result;
      this.logFileSize(result);
      const fileName = this.type + new Date().getTime() + '.jpeg';
      await Filesystem.writeFile({
        path: `${IMAGE_DIR}/${fileName}`,
        data: this.imgResultAfterCompress,
        directory: Directory.Data,
        recursive: true,
      });
      this.imagesTest = [];
      console.debug(this.imagesTest);
  
      try {
        const result = await Filesystem.readdir({
          path: IMAGE_DIR,
          directory: Directory.Data,
        });
        console.log('Starting LoadFileData', result.files);
  
        for (let f of result.files) {
          const filePath = `${IMAGE_DIR}/${f.name}`;
          console.log('what is file path', filePath);
          const readFile = await Filesystem.readFile({
            path: filePath,
            directory: Directory.Data,
          });
          this.imagesTest.push({
            name: f.name,
            path: filePath,
            data: `data:image/jpeg;base64,${readFile.data}`,
          });
          console.warn('processPhoto service has completed');
          console.warn('This is the images: ', this.imagesTest);
          this.changeStatus('true');
        }
      } catch (err) {
        console.debug('error in load images: ', err);
        // Folder does not yet exists!
        await Filesystem.mkdir({
          path: IMAGE_DIR,
          directory: Directory.Data,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.dismiss();
    }
  }

  // #endregion
}