import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';

import { Capacitor } from '@capacitor/core';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getApp } from 'firebase/app';
import { TitleCasePipe } from '@angular/common';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LoggerModule } from 'ngx-logger';

import { REGION } from '@angular/fire/compat/functions';

import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

import { SharedDirectivesModule } from './directives/shared-directives.module';
import { Logger } from './services/logs/logger.service';


@NgModule({
  declarations: [AppComponent],
 // entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    SharedDirectivesModule,
    AppRoutingModule,
    LoggerModule.forRoot(environment.logging),
    provideStorage(() => getStorage()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        console.warn('Capacitor Is Native');
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        console.warn('Capacitor is not Native');
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, }),},
    { provide: Sentry.TraceService, deps: [Router],},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: REGION, useValue: 'us-central1' },
    TitleCasePipe,
    InAppBrowser,
    Logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
