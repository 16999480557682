import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../services/authentication/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard  {
  constructor(
    private auth: Auth,
    private route: Router,
    private authSvc: AuthService
  ) {}
  canActivate(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      onAuthStateChanged(this.auth, async (user) => {
        if (user) {
          const i = await this.authSvc.isUserClient(user);
          if (i) {
            this.route.navigateByUrl('/private/tabs/landing', { replaceUrl: true });
            reject(false);
          } else {
            this.route.navigateByUrl('/private/home', { replaceUrl: true,});
            reject(false);
          }
        } else {
          if(user == null) {
            resolve(true);
          }
        }
      });
    });
  }
}
