import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { doc, docData, Firestore, getDoc, updateDoc } from '@angular/fire/firestore';
import { Observable } from '@firebase/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppVersion, ForceAppUpdate, License } from 'src/app/interfaces/interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {

  logout$: Subject<boolean> = new Subject<boolean>();
  constructor(private firestore: Firestore, private firebaseAuth: Auth) {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (!user) {
        this.logout$.next(true);
      }
    });
  }

  // Check Min Supported App Version
  async checkAppMinVersion(): Promise<string | undefined> {
    const docRef = doc(this.firestore, 'appVersion', 'values');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const { minVersion } = docSnap.data();
      console.debug('checkAppMinVersion data:', docSnap.data());
      console.debug('it returned minVersion', minVersion);
      return minVersion;
    } else {
      console.debug('Could not read Min App Version');
    }
  }

  getAppValues(){
    const docRef = doc(this.firestore, 'appVersion', 'values');
    return docData(docRef, { idField: 'id' });
  }

  async setMinAppVersion(data) {
    const docRef = doc(this.firestore, 'appVersion', 'values');
    await updateDoc(docRef, { minSupportedVersion: data });
    return true;
  }

  async setCurrentAppVersion(id) {
    const docRef = doc(this.firestore, 'appVersion', 'values');
    await updateDoc(docRef, { currentVersion: id });
    return true;
  }

  async setLatestAppVersion(data) {
    console.warn('setApp', data);
    const docRef = doc(this.firestore, 'appVersion', 'values');
    await updateDoc(docRef, { latestVersion: data });
    return true;
  }

  checkSubscription(): Observable<License> {
    const docRef = doc(this.firestore, `appVersion/license`);
    return docData(docRef, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as unknown as Observable<License>;
  }

  checkVersion(): Observable<ForceAppUpdate> {
    const docRef = doc(this.firestore, `appVersion/values`);
    return docData(docRef, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as unknown as Observable<ForceAppUpdate>;
  }

  //#region /////// Version Checks //////////////


  async forceNewVersion(isAdmin, min) {
    const myVersion = this.getCurrentAppVersion();
    const minVersion = min;
    const force = this.isMyVersionLessThanMin(myVersion, minVersion);
    if (isAdmin) {
      const version = await this.getLatestVersion();
      const check = this.isMyVersionGreaterThanLatest(myVersion, version);
      
      if(check) {
        this.setLatestAppVersion(myVersion);
        console.warn('Admin updated lastest appversion');
      }

    }
    return force;
  }

  isMyVersionLessThanMin(myVersion: AppVersion, min: AppVersion): boolean {
    if (myVersion.major < min.major) {
      return true;
    } else if (myVersion.major === min.major) {
      if (myVersion.minor < min.minor) {
        return true;
      } else if (myVersion.minor === min.minor) {
        if (myVersion.revision < min.revision) {
          return true;
        }
      }
    }
    return false;
  }

  isMyVersionGreaterThanLatest(myVersion: AppVersion, latest: AppVersion): boolean {
    if (myVersion.major > latest.major) {
      return true;
    } else if (myVersion.major === latest.major) {
      if (myVersion.minor > latest.minor) {
        return true;
      } else if (myVersion.minor === latest.minor) {
        if (myVersion.revision > latest.revision) {
          return true;
        }
      }
    }
    return false;
  }

  async getMinSupportedAppVersion() {
    const docRef = doc(this.firestore, 'appVersion', 'values');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const { minSupportedVersion } = docSnap.data();
      console.debug('checkAppMinVersion data:', docSnap.data());
      console.debug('it returned minVersion', minSupportedVersion);
      return minSupportedVersion;
    } else {
      console.debug('Could not read Min App Version');
    }
  }

  getCurrentAppVersion() {
    const versionString = environment.appVersion;
    const versionRegex = /^(\d+)\.(\d+)\.(\d+)$/;
    const match = versionString.match(versionRegex);

    if (match) {
      const major = parseInt(match[1], 10);
      const minor = parseInt(match[2], 10);
      const revision = parseInt(match[3], 10);

      if (!isNaN(major) && !isNaN(minor) && !isNaN(revision)) {
        return { major, minor, revision };
      }
    }

    return null;
  }

  async getLatestVersion() {
    const docRef = doc(this.firestore, 'appVersion', 'values');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const { latestVersion } = docSnap.data();
      return latestVersion;
    } else {
      console.debug('Could not read Current App Version');
    }
  }

  //#endregion ///////////////////////////////////
}
