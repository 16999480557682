<ion-app>
  <ion-menu side="start" menuId="menu" content-id="landing">
    <ion-header>
      <ion-toolbar color='#00a6aa'>
        <ion-title mode="md">Pro Captain Staffing</ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="profile">
      <div class="avatar">
        <ion-avatar>
          <img class="logo" *ngIf="profileImg?.imageUrl; else placeholder_avatar;" [src]="profileImg.imageUrl" />
          <ng-template #placeholder_avatar>
            <img class="logo" src="./assets/img/default-avatar.jpg" />
          </ng-template>
        </ion-avatar>
      </div>
      <div>
        <div class="name">{{ captainName }}</div>
        <div class="title">
          <ion-badge mode="md">{{badgeText}}</ion-badge>
        </div>
      </div>
    </div>

    <ion-content>
      <ion-list lines="none">
        <ion-menu-toggle>
          <ion-item (click)="startNewReport()" *appHasRole="['user']">
            <ion-icon class="iconColor" name="create" slot="start"></ion-icon>
            <ion-label>Start New Report</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item (click)="goToTimeClock()" *appHasRole="['user']">
            <ion-icon class="iconColor" name="time" slot="start"></ion-icon>
            <ion-label>Clock-in / Clock-out</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item (click)="goToTimeClock()" *appHasRole="['mate']">
            <ion-icon class="iconColor" name="time" slot="start"></ion-icon>
            <ion-label>Clock-in / Clock-out</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item (click)="goToTimeClock()" *appHasRole="['tech']">
            <ion-icon class="iconColor" name="time" slot="start"></ion-icon>
            <ion-label>Clock-in / Clock-out</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToAssignInspections()">
            <ion-icon class="iconColor" name="flash-sharp" slot="start"></ion-icon>
            <ion-label>Assign Inspections</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item (click)="goToMyList()" *appHasRole="['user']">
            <ion-icon class="iconColor" name="flag-sharp" slot="start"></ion-icon>
            <ion-label>My Assigned Boats</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle *ngIf="apView" >
          <ion-item (click)="goToBoatProfile()" *appHasRole="['user']">
            <ion-icon class="iconColor" name="boat" slot="start"></ion-icon>
            <ion-label>Boat Profile</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle *appHasRole="['tech']" >
          <ion-item (click)="goToBoatProfile()" >
            <ion-icon class="iconColor" name="boat" slot="start"></ion-icon>
            <ion-label>Boat Profile</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
        
            <ion-item (click)="goToChats()" *ngIf="msgNotifications > 0 && apView; else placeholder_zeromsg;">
              <ion-icon class="iconColor" name="chatbubbles-sharp" slot="start"></ion-icon>
              <ion-label>Chats</ion-label>
              <ion-badge class="chatBadge" slot="end">{{msgNotifications}}</ion-badge>
            </ion-item>

            <ng-template #placeholder_zeromsg>
              <ion-item *ngIf="apView" (click)="goToChats()">
                <ion-icon class="iconColor" name="chatbubbles-sharp" slot="start"></ion-icon>
                <ion-label>Chats</ion-label>
              </ion-item>
            </ng-template>

          
        </ion-menu-toggle>
<!-- 
        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToAddClients()">
            <ion-icon class="iconColor" name="add-outline" slot="start"></ion-icon>
            <ion-label>Add Client</ion-label>
          </ion-item>
        </ion-menu-toggle> -->


        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToClients()">
            <ion-icon class="iconColor" name="people" slot="start"></ion-icon>
            <ion-label>View Client List</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToPayroll()">
            <ion-icon class="iconColor" name="logo-usd" slot="start"></ion-icon>
            <ion-label>Payroll</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToAdmin()">
            <ion-icon class="iconColor" name="settings" slot="start"></ion-icon>
            <ion-label>Admin</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item (click)="goToProfile()">
            <ion-icon class="iconColor" name="person-circle" slot="start"></ion-icon>
            <ion-label>My Profile</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToAddNewUser()">
            <ion-icon class="iconColor" name="finger-print" slot="start"></ion-icon>
            <ion-label>User Management</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToDbSettings()">
            <ion-icon class="iconColor" name="logo-capacitor" slot="start"></ion-icon>
            <ion-label>Database</ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle *ngIf="isDev">
          <ion-item (click)="consoleLogs()">
            <ion-icon class="iconColor" name="logo-codepen" slot="start"></ion-icon>
            <ion-label>Console Logs</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle *appHasRole="['admin']">
          <ion-item (click)="goToServiceRequests()">
            <ion-icon class="iconColor" name="build-sharp" slot="start"></ion-icon>
            <ion-label>Requests / Trips</ion-label>
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle>
          <ion-item>
            <ion-icon class="iconColor" name="logo-apple" slot="start"></ion-icon>
            <ion-label>App Version {{ currentApplicationVersion }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle>
          <ion-item (click)="logout()" class="btn">
            <ion-icon name="logo-edge" slot="start"></ion-icon>
            <ion-label>Sign Out</ion-label>
            <ion-icon name="chevron-forward" slot="end"></ion-icon>
          </ion-item>
        </ion-menu-toggle> -->
      </ion-list>

    </ion-content>
    <ion-footer>
      <ion-menu-toggle>
        <ion-item (click)="logout()" class="btn">
          <ion-icon name="logo-edge" slot="start"></ion-icon>
          <ion-label>Sign Out</ion-label>
          <ion-icon name="chevron-forward" slot="end"></ion-icon>
        </ion-item>
      </ion-menu-toggle>
    </ion-footer>
  </ion-menu>

  <ion-menu side="end" [type]="menuType" menuId="contacts" contentId="landing" *ngIf="showUsers">
    <ion-header>
      <ion-toolbar color='#00a6aa'>
        <ion-title>Users in Chat</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="listClass">
        <!-- <ion-row class="rowClass">  -->

          <ion-item  lines="none"  *ngFor="let i of chatUsers" (click)="confirmKick(i)" >
            <ion-avatar slot="start">
              <img class="logo" *ngIf="i.pic; else placeholder_avatar;" [src]="i.pic" />
              <ng-template #placeholder_avatar>
                <img class="logo" src="./assets/img/default-avatar.jpg" />
              </ng-template>
            </ion-avatar>
            <ion-label>
              {{i.user}}
            </ion-label>
          </ion-item>
          
        <!-- </ion-row> -->
   <!-- <ion-item-divider color="dark"></ion-item-divider> -->
   <hr>
          <ion-item  lines="none" (click)="backConfirm()">
            <ion-icon class="iconColor" name="exit-outline" slot="end"></ion-icon>
            <ion-label>Leave Chat</ion-label>
          </ion-item>
          <ion-item class="chatIdData" lines="none" *appHasRole="['admin']">
            <ion-label>Chat ID: {{chatId}}</ion-label>
          </ion-item>

      </ion-list>
      
    </ion-content>
  </ion-menu>


  <ion-router-outlet id="landing">

  </ion-router-outlet>
</ion-app>