import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { collection, doc, docData, Firestore, getDoc, getDocs, query, updateDoc, where } from '@angular/fire/firestore';
import { getDownloadURL, ref, Storage, uploadString } from '@angular/fire/storage';
import { User, UserClientId } from 'src/app/interfaces/interface';
import { Photo } from '@capacitor/camera';
import { DbDataService } from '../database/db-data.service';
import { Logger } from '../../logs/logger.service';


@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private storage: Storage,
    private dbData: DbDataService,
    private logger: Logger
  ) {}

  async checkEmail(email) {
    const usersRef = collection(this.firestore, 'users');

    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  }
  getUserProfile() {
    const user = this.auth.currentUser;
    const userDocRef = doc(this.firestore, `users/${user.uid}`);
    return docData(userDocRef, { idField: 'id' });
  }

  async getUserPicture(id) {
    const user = this.auth.currentUser;
    const ref = doc(this.firestore, `users/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const data = docSnap.data();
      console.debug('getUserPicture data:', docSnap.data());
      return data.imageUrl;
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in getUserPicture');
      return null;
    }
  }

  async uploadProfileImg(cameraFile: Photo) {
    const user = this.auth.currentUser;
    const path = `uploads/${user.uid}/profile.png`;
    const storageRef = ref(this.storage, path);

    try {
      await uploadString(storageRef, cameraFile.base64String, 'base64');

      const imageUrl = await getDownloadURL(storageRef);

      const userDocRef = doc(this.firestore, `users/${user.uid}`);
      await updateDoc(userDocRef, {
        imageUrl,
      });
      return true;
    } catch (e) {
      return null;
    }
  }

  async updateUserInfo(user: User) {
    const ref = doc(this.firestore, `users/${user.id}`);
    try {
      await updateDoc(ref, {
        first_name: user.first_name,
        last_name: user.last_name,
        display: user.display,
        email: user.email,
        role: user.role,
        inspector: user.inspector,
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getUserInfoById(data?) {
    console.log('values recieved', data);
    var id;
    if(data) {
      id = data
    } else {
      const user = this.auth.currentUser;
      id = user.uid;
    }
    const ref = doc(this.firestore, `users/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      console.debug('getUserInfoById data:', docSnap.data());
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in getUserInfoById');
    }
  }

  async getUserInfoByName(name) {
    console.log('values recieved', name);
    const ref = collection(this.firestore, `users`);
    const q = query(ref, where('display', '==', name));
    const querySnapshot = await getDocs(q);
    let result: string;
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, ' => ', doc.data());
      result = doc.id;
      return result;
    });
    return result;
  }

  async getUserClientId(id): Promise<UserClientId> {
    console.log('values recieved', id);
    const ref = doc(this.firestore, `users/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      console.debug('getUserInfoById data:', docSnap.data());
      const data = docSnap.data();
      if (data.clientId2) {
        var idArray = [];
        const boat1 = data.clientId;
        const boat2 = data.clientId2;
        return { id1: boat1, id2: boat2 };
      } else {
        const boat1 = data.clientId;
        return { id1: boat1 };
      }
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in getUserInfoById');
    }
  }

  async getUserDisplayName(id) {
    console.log('values recieved', id);
    const ref = doc(this.firestore, `users/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      console.debug('getUserInfoById data:', docSnap.data());
      const data = docSnap.data();
      return data.display;
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in getUserInfoById');
    }
  }

  async getUserNotificationsPref(id) {
    console.log('UserPref got id', id)
    const ref = doc(this.firestore, 'users', id);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      console.debug('getUserNotificationsPref data:', docSnap.data());
      const data = docSnap.data();
      return data.permissions;
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in getUserNotificationsPref');
    }
  }

  async isUserInspector(id) {
    console.log('Checking for Inspector', id);
    const ref = doc(this.firestore, `users/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      console.debug('isUserInspector data:', docSnap.data());
      const data = docSnap.data();
      if (data.inspector) {
        return true;
      } else {
        return false;
      }
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in isUserInspector');
      return false;
    }
  }

  async isUserDeveloper(id) {
    console.log('Checking for Developer', id);
    const ref = doc(this.firestore, `users/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
   //   console.debug('isUserDeveloper data:', docSnap.data());
      const data = docSnap.data();
      if (data.dev) {
        return true;
      } else {
        return false;
      }
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in isUserDeveloper');
      return false;
    }
  }

  async getOwnerForChat(): Promise<{
    id: string;
    display: string;
    email: string;
  }> {
    const owner = await this.dbData.getOwnerValue();
    const querySnapshot = await getDocs(
      query(collection(this.firestore, 'users'), where('display', '==', owner))
    );
    const doc = querySnapshot.docs[0];
    return {
      id: doc.id,
      display: doc.data().display,
      email: doc.data().email,
    };
  }

  async findClientUserId(id): Promise<string | null> {
    console.log('findClientUserId got', id);
    const ref = collection(this.firestore, 'users');
    const q = query(ref, where('clientId', '==', id));
    const results = [];
    const querySnapshot = await getDocs(q);
  
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, '=>', doc.data());
      results.push(doc.id);
    });
  
    if (results.length > 0) {
      return results[0];
    } else {
      return null;
    }
  }

  async getAllUsers() {
    const ref = collection(this.firestore, 'users');
    const results = [];
    const querySnapshot = await getDocs(ref);
  
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const data = doc.data();
    //  console.log(doc.id, '=>', doc.data());
      results.push(data);
    });
  
    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  async getAllClients() {
    const userRef = collection(this.firestore, 'users');
    
const querySnapshot = await getDocs(userRef);
querySnapshot.forEach(async (doc) => {
  // doc.data() is never undefined for query doc snapshots
  const fcm = await this.isFCMToken(doc.id);

  if (fcm) {
    await this.updatePref(doc.id, fcm);
  } else {
    await this.updatePref(doc.id, false);
  }
  console.log(doc.id, " => ", );;
});
  }

  async isFCMToken(id) {
    const ref = doc(this.firestore, `FCMToken/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
   //   console.debug('isUserDeveloper data:', docSnap.data());
      return true;
    } else {
      // doc.data() will be undefined in this case
      console.error('No Document Data found in isUserDeveloper');
      return false;
    }
  }

  async updatePref(id, value: boolean) {
    console.log('updating pref for', id, 'value', value);
    const ref = doc(this.firestore, `users/${id}`);
    try {
      await updateDoc(ref, {
        permissions: {
          pushNotifications: value,
          emailNotifications: false,
        }
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  async searchFcmByName(full: string) {
   // const ref = doc(this.firestore, 'FCMToken');
  
    const q = query(collection(this.firestore, "FCMToken"), where("user", "==", full));

    const querySnapshot = await getDocs(q);
    var answer = false;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data) {
        answer = true
      } else {
        answer = false
      }
    });
return answer;
  }

  async setLastLogInTime(userId) {
    const ref = doc(this.firestore, `users/${userId}`);
    try {
      const date = new Date();
      await updateDoc(ref, { lastLogIn: date.toLocaleDateString() });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
