import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Firestore, collection, collectionData, doc, docData, addDoc, deleteDoc, updateDoc, orderBy, query, getDoc, where, getDocs, setDoc} from '@angular/fire/firestore';
import { Client } from 'src/app/interfaces/interface';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { takeUntil } from 'rxjs/operators';
import { DataService } from '../../device/local/data.service';
import { Logger } from '../../logs/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  logout$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private firestore: Firestore,
    private firebaseAuth: Auth,
    private localData: DataService,
    private logger: Logger
  ) {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (!user) {
        this.logout$.next(true);
      }
    });
  }

  getClients(): Observable<Client[]> {
    const notesRef1 = query(
      collection(this.firestore, 'clients'),
      orderBy('lName')
    );
    const notesRef = query(
      collection(this.firestore, 'clients'),
      where('isActiveClient', '!=', false)
    );
    const notesRef3 = query(
      collection(this.firestore, 'clients')
    );
    return collectionData(notesRef, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as Observable<Client[]>;
  }
  
  
  // getClients2(): Observable<newClient[]> {
  //   const notesRef1 = query(
  //     collection(this.firestore, 'pcsClients'),
  //     orderBy('lastName')
  //   );
  //   const notesRef = query(
  //     collection(this.firestore, 'pcsClients'),
  //     where('isActiveClient', '!=', false)
  //   );
  //   const notesRef3 = query(
  //     collection(this.firestore, 'pcsClients')
  //   );
  //   return collectionData(notesRef3, { idField: 'id' }).pipe(
  //     takeUntil(this.logout$)
  //   ) as Observable<newClient[]>;
  // }
  

  getClientsChat(): Observable<Client[]> {
    const notesRef1 = query(
      collection(this.firestore, 'clients'),
      orderBy('lName')
    );
    const notesRef = query(
      collection(this.firestore, 'users'),
      where('role', '==', 'CLIENT')
    );
    return collectionData(notesRef, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as Observable<Client[]>;
  }

  getInactiveClients(): Observable<Client[]> {
    const notesRef = query(
      collection(this.firestore, 'clients'),
      where('isActiveClient', '!=', true)
    );
    return collectionData(notesRef, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as Observable<Client[]>;
  }

  getClientById(id: string): Observable<Client> {
    const noteDocRef = doc(this.firestore, `clients/${id}`);
    return docData(noteDocRef, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as Observable<Client>;
  }

  async getClientLocation(id: string) {
    const ref = doc(this.firestore, `clients/${id}`);

    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const result = docSnap.data();
      return result.location.lake;
    } else {
      console.info('getClientLocation : No location found');
      return null;
    }
  }

  async getImgUrl(id: string) {
    console.log('getImg client id', id);
    const ref = doc(this.firestore, `clients/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const result = docSnap.data();
      return result.boatInfo.photoUrl;
    } else {
      console.info('getClientLocation : No location found');
      return null;
    }
  }

  // addClient(client: Client) {
  //   const notesRef = collection(this.firestore, 'clients');
  //   return addDoc(notesRef, client);
  // }
  addClient(client: Client) {
    const notesRef = collection(this.firestore, 'clients');
    return addDoc(notesRef, client);
  }


  async addNewClient(id: string, client: Client) {
    console.log('what is Id?', id)
    const notesRef = doc(this.firestore, 'clients', id);
    console.log('adding doc')
    await setDoc(notesRef,client);
  }

  deleteClient(client: Client) {
    const noteDocRef = doc(this.firestore, `clients/${client.id}`);
    return deleteDoc(noteDocRef);
  }
  updateClient(client: Client) {
    
    const noteDocRef = doc(this.firestore, `clients/${client.id}`);
    return updateDoc(noteDocRef,  {
      isActiveClient: client.isActiveClient,
      isTestClient: client.isTestClient,
      doesClientHaveMobileApp: false,
      serviceLevel: client.serviceLevel,
      firstName: client.firstName,
      lastName: client.lastName,
      fullName: client.fullName,
      email:  client.email,
      boatInfo: {
        name: client.boatInfo.name,
        make: client.boatInfo.make,
        model: client.boatInfo.model,
        description: client.boatInfo.description,
        isWakeboardBoat: client.boatInfo.isWakeboardBoat,
        numberOfEngines: client.boatInfo.numberOfEngines,
        photoUrl: client.boatInfo.photoUrl,
      },
      lastInspection: {
        reportId: client.lastInspection.reportId,
        reportUrl: client.lastInspection.reportUrl,
        inspectionDate: client.lastInspection.inspectionDate,
      },
      location: {
        lake: client.location.lake,
        marina: client.location.marina,
        slip: client.location.slip,
      }, });
  }

  updateClientStatus(id: string, status: boolean) {
    const ref = doc(this.firestore, `clients/${id}`);
    return updateDoc(ref, { isActiveClient: status });
  }

  // updateTestClient(id: string) {
  //   const status = false;
  //   const ref = doc(this.firestore, `clients/${id}`);
  //   return updateDoc(ref, { isActiveClient: status });
  // }

  updateClientEmail(id: string, email: string) {
    const ref = doc(this.firestore, `clients/${id}`);
    return updateDoc(ref, { email: email });
  }

  updateClientMobileAppStatus(id: string, status: boolean) {
    const ref = doc(this.firestore, `clients/${id}`);
    return updateDoc(ref, { doesClientHaveMobileApp: status });
  }

  private async getClientId(email: string, firestore: any) {
    const q = query(
      collection(firestore, 'clients'),
      where('email', '==', email)
    );

    const querySnapshot = await getDocs(q);
    let clientId: string | undefined;
    querySnapshot.forEach((doc) => {
      clientId = doc.id;
    });
    return clientId;
  }

  public async getClientIdByBoatName(boat: string) {
    const q = query(
      collection(this.firestore, 'clients'),
      where('boatInfo.name', '==', boat)
    );

    const querySnapshot = await getDocs(q);
    let clientId;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      clientId = { id: doc.id, docData: data };
      return clientId;
    });
    return clientId;
  }

  async whatIsClientId(): Promise<string | undefined> {
    const email = this.localData.userLoggedIn.email;
    const clientId = await this.getClientId(email, this.firestore);
    this.localData.userLoggedIn.clientId = clientId;
    return clientId;
  }

  async getClientBoatById(id: string) {
    console.log('getClientBoatById what is id being passed', id);
    const docRef = doc(this.firestore, 'clients', `${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data.boatInfo.name;
    } else {
      console.log(' getClientBoatById No such document!');
    }
  }

  async getClientBoatInfo(id: string) {
    console.log('getClientBoatInfo what is id being passed', id);
    const docRef = doc(this.firestore, 'clients', `${id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      console.log(' getClientBoatInfo No such document!');
    }
  }
 
  async getEmailList() {
    const q = query(
      collection(this.firestore, 'clients'), where('email', '!=', ""));

    const querySnapshot = await getDocs(q);
    let results = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
     //  console.log('email list', doc.id, ' => ', doc.data());
      const data = doc.data();
      const finalData = {
        id: doc.id,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: data.fullName,
        email: data.email
      }
      results.push(finalData);
    });
    return results;
  }


}
