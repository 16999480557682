import { Injectable } from '@angular/core';
import { addDoc, collection, doc, Firestore, setDoc, updateDoc } from '@angular/fire/firestore';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { ScheduleService } from '../scheduler/schedule.service';
import { DataService } from '../../device/local/data.service';
import { DeviceService } from '../../device/info/device.service';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { Subject } from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { MobileReportNotify } from 'src/app/interfaces/schedule';
import { Logger } from '../../logs/logger.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PushService {
logout$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private schedule: ScheduleService,
    private firestore: Firestore,
    private localData: DataService,
    private device: DeviceService,
    private firebaseAuth: Auth,
    private auth: AuthService,
    private logger: Logger,
    private router: Router
  ) 
  {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (!user) {
        this.logout$.next(true);
      }
    });
  }
   
  initPush() {
    if (Capacitor.isNativePlatform() === true) {
      this.registerPush();
    //  PushNotifications.removeAllDeliveredNotifications();
    } else {
      console.log('Not Native Platform so not registering Push');
    }
  }
   async updateFCMToken(id: string) {

    // * Get and set variables and data
    // const myDisplay = this.localData.captainName.displayName;
    const userId = this.auth.getUserId();
    const uuid = await this.device.getDeviceId();
    const user = this.localData.captainName;
    const date = new Date();
    const system = await this.device.getDeviceInfo();

    const data = {
      user: user.displayName,
      deviceId: uuid,
      tokenId: id,
      date: date,
      os: system.operatingSystem + ' ' + system.osVersion,
      model: system.model,
    };

    console.debug(data);
    this.localData.pushNotifications = true;

    // * Call Firestore
    const ref = doc(this.firestore, 'FCMToken', `${userId}`);
    const i = await setDoc(ref, data);

    const userRef = doc(this.firestore, 'users', `${userId}`);
    const x = await updateDoc(userRef, { 'permissions.pushNotifications': true });
    return i;
  }

  removeAllListeners() {
    PushNotifications.removeAllListeners();
  }

  async registerPush() {
    await this.auth.getCurrentUser();
    console.log('started RegisterPush');
    PushNotifications.requestPermissions().then(async (permission) => {
      if (permission.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        console.debug('permission granted');
        PushNotifications.register();
      } else {
        // Show some error
        console.debug('User denied push notifications, setting notify to false in profile');
        await this.turnOffPush();
      }
    });
    PushNotifications.addListener('registration', (token: Token) => {
      console.debug('Push registration success, token: ' + token.value);
      this.updateFCMToken(token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.debug('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.debug('Push received: ' + JSON.stringify(notification));
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      const data = notification.notification.data;
      console.debug('Push action performed: ' + JSON.stringify(notification.notification));
      if(data.id) {
        console.log('Push Data:', data.id);
        this.router.navigateByUrl(`/private/chat-main/${data.id}`);
      }
    });
  }

  async sendMobileReportNotification(data: MobileReportNotify) {
    const ref = collection(this.firestore, 'mobileReportMsg');
    const result = await addDoc(ref, data);
    return result;
  }

  resetBadgeCount() {
    PushNotifications.removeAllDeliveredNotifications();
  }

  async turnOffPush() {
    const userId = this.auth.getUserId();
    console.log('Turning off push for ', userId);
    const userRef = doc(this.firestore, 'users', `${userId}`);
    const x = await updateDoc(userRef, { 'permissions.pushNotifications': false });
  }

  async turnOnEmail() {
    const userId = this.auth.getUserId();
    console.log('Turning on email notifications for ', userId);
    const userRef = doc(this.firestore, 'users', `${userId}`);
    const x = await updateDoc(userRef, { 'permissions.emailNotifications': true });
  }

  async turnOffEmail() {
    const userId = this.auth.getUserId();
    console.log('Turning off emaiil notifications for ', userId);
    const userRef = doc(this.firestore, 'users', `${userId}`);
    const x = await updateDoc(userRef, { 'permissions.emailNotifications': false });
  }


}
