import { Component } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './services/alert/alert.service';
import { AuthService } from './services/authentication/auth.service';
import { DataService } from './services/device/local/data.service';
import { StorageService } from './services/device/storage/storage.service';
import { LicenseService } from './services/firebase/license/license.service';
import { PushService } from './services/firebase/notifcations/push.service';
import { UserService } from './services/firebase/users/user.service';
import { Geolocation } from '@capacitor/geolocation';
import { ChatService } from './services/firebase/chat/chat.service';
import { AppVersion } from './interfaces/interface';
import { Logger } from './services/logs/logger.service';
import { ConsoleViewPage } from './private/shared/console-view/console-view.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private subscriptions = new Subscription();
  refreshStatus: boolean;
  key1: string;
  key2: string;
  captainName: string;
  badgeText: string;
  profileImg = null;
  license: boolean;
  forceApp: boolean;
  logout$: Subject<boolean> = new Subject<boolean>();
  menuType: string = 'push';
  chatUsers = [];
  chatId;
  showUsers: boolean;
  msgNotifications: number;
  apView = true;
  currentVersion: AppVersion;
  isDev = false;

  constructor(
    private route: Router,
    private auth: AuthService,
    private loading: LoadingController,
    private pref: StorageService,
    private alert: AlertService,
    private alertCon: AlertController,
    private localData: DataService,
    private push: PushService,
    private user: UserService,
    private version: LicenseService,
    private firebaseAuth: Auth,
    private platform: Platform,
    private chat: ChatService,
    private alertC: AlertController,
    private logger: Logger,
    private viewConsole: ModalController
  ) {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (!user) {
        this.logout$.next(true);
      }
    });
  }

  currentApplicationVersion: string;

  async ngOnInit() {
  //  this.logger.logFileInit();
    this.currentApplicationVersion = environment.appVersion;
    console.log('App Version: ', this.currentApplicationVersion);
    this.localData.appVersion = environment.appVersion;
    this.getAuthState();
    this.chatUsers = this.localData.chatUsers;
    this.chatId = this.localData.currentChatId;
  //  console.log('ionViewDidEnter', this.localData.chatUsers);
    this.subChatUsers();
  }

  async checkForUnreadMsg() {
    const myId = this.auth.getUserId();
    console.log('Checking for unread chat messages for', myId);
    const sub = (await this.chat.totalUnreadMessages(myId)).subscribe((res) => {
      console.warn('Unread Message Observable Update:', res);
      this.msgNotifications = res.count;
    });
    console.warn('Number of total unread messages for', myId, '=', this.msgNotifications);
    this.subscriptions.add(sub);
    this.listenForMsgs();
  }

  async listenForMsgs() {
    const id = this.auth.getUserId();
    const isClient = await this.auth.isAppClient(id);

    if(isClient) {
      console.log('User is a client, not subscribing here for msg updates');
    } else {
      this.msgNotifications = this.localData.unreadMsgNotifcations;
      const msgSub = this.localData.unreadMsg$.subscribe((newMsg) => {
        console.log('Listening for msgs, badge count =', this.msgNotifications, 'msgObservable = ', newMsg);
        if (newMsg) {
          this.msgNotifications = this.localData.unreadMsgNotifcations;
          console.log('Updating Badge Count to:', this.msgNotifications);
        };
      });   
      this.subscriptions.add(msgSub);
    }

  }

  subChatUsers() {
    const imgSub = this.localData.refreshUsers.subscribe((refresh) => {
      this.refreshStatus = refresh;
      if (this.refreshStatus === true) {
        console.log('Refresh the Chat users: ', this.refreshStatus);
        // Reset array to blank to solve for duplication bug
        this.chatUsers = [];
        this.showUsers = true;
        this.chatUsers = this.localData.getChatUsers();
        this.chatId = this.chatUsers[0].chatId;
        console.log('Chat Users Array is now: ', this.chatUsers);
        this.chatUsers = this.removeDuplicateObjects(this.chatUsers, 'id');
        console.warn(this.chatUsers);
      } else {
        console.log('Refresh the Chat users: ', this.refreshStatus, ' Set showUsers to FALSE');
        this.showUsers = false;
      }
    });

    this.subscriptions.add(imgSub);
    this.localData.resetChatUsers();
  }

  removeDuplicateObjects<T extends Record<string, any>>(arr: T[], property: keyof T): T[] {
    const uniqueObjects: { [key: string]: T } = {};

    arr.forEach(obj => {
        const key = obj[property];
        if (!(key in uniqueObjects)) {
            uniqueObjects[key] = obj;
        }
    });

    return Object.values(uniqueObjects);
}

  //#region /////////// Loading Page ///////////

  getAuthState() {
    const token = this.auth
      .getUserSubject()
      .pipe(takeUntil(this.logout$))
      .subscribe((authState) => {
        if (authState === null) {
          this.getCreds();
          this.checkForPermissions();
        }
      });
    this.subscriptions.add(token);
  }

  async checkForPermissions() {
    const permission = await Geolocation.checkPermissions()
      .then((res) => {
        console.log('User already has permission');
      })
      .catch((error) => {
        console.log('there was an error', error);
        this.askPermissions();
      });
  }

  async askPermissions() {
    await Geolocation.requestPermissions()
      .then(async (request) => {
        console.log('This is the request', request);
        if (request.location === 'denied') {
          console.log('[askPermissions] was denied by user', request);
          const alert = await this.alertCon.create({
            header: 'Location Error',
            message: 'Please go into settings and change GPS to While Using the App',
          });
          await alert.present();
        } else {
        }
      })
      .catch((error) => {
        console.log('[askPermissions] was denied by user', error);
      });
  }

  async getUserProfile() {
    await this.checkForUnreadMsg();
    if (!this.captainName) {
      console.log('Cannot set badge name, getUserProfile');
      this.captainName = this.localData.userLoggedIn.displayName;
      const user = this.containsApple(this.captainName);
      console.warn('APPLE CHECK', user);
      if (user) {
        this.apView = false;
      }

      this.push.initPush();
      const userProfile = this.user.getUserProfile().subscribe((data) => {
        this.profileImg = data;
        console.warn('Data validate: ', data)
        if(this.profileImg.dev) {
          this.isDev = true;
          console.log('is user a DEV? :', this.isDev);
        }
        if (this.apView) {
          this.badgeText = this.localData.userLoggedIn.badge;
        } else {
          this.badgeText = 'Mate';
        }
      });
      this.subscriptions.add(userProfile);
    }
  }

  containsApple(inputString: string): boolean {
    const targetWord = 'Apple';
    const regex = new RegExp(`\\b${targetWord}\\b`, 'i'); // The 'i' flag makes the match case-insensitive
    return regex.test(inputString);
  }
  //#endregion ///////////////////////////////

  //#region ///////// Main Code ///////////////
  async getCreds(): Promise<void> {
    const start = await this.loading.create({
      message: 'Reauthenticating...',
      cssClass: 'custom-loading',
    });
    await start.present();

    try {
      const key1 = await this.pref.getKey('email');
      const key2 = await this.pref.getKey('password');

      if (key1 && key2) {
        console.warn('[getCreds()] calling Login now');
        await this.auth.reLogin(key1, key2).then(async (res) => {
          await this.user.setLastLogInTime(res.uid);
          await this.checkLicense();
          await this.checkForNewVersion();
        });
      } else {
        console.info('Could not get credentials, sending to login');
        await this.pref.clearKeys();
        this.route.navigateByUrl('/welcome', { replaceUrl: true });
      }
    } catch (error) {
      console.error(error);
      await this.alert.tokenExpired(error);
    } finally {
      await start.dismiss();
    }
  }

  async checkLicense() {
    const vers = this.version.checkSubscription().subscribe((res) => {
      this.license = res.active;
      console.log('Is license active? ', this.license);
      if (this.license != true) {
        const msgTitle = 'Subscription Inactive';
        const msgSub = 'This app license is no longer valid';
        const msgBody = 'Please contact PCS representive to renew license';

        this.alert.inactiveLicense(msgTitle, msgSub, msgBody, false);
      } else {
        this.alert.dismissLicense();
        this.checkForNewVersion();
      }
    });
    this.subscriptions.add(vers);
    this.getUserProfile();
  }

  async checkForNewVersion() {
    const force = this.version.checkVersion().subscribe(async (res) => {
      const isAdmin = this.auth.hasRoles(['admin']);
      this.forceApp = await this.version.forceNewVersion(isAdmin, res.minSupportedVersion);
      console.log('force sub got update,', this.forceApp);
      if (this.forceApp === true) {
        const version = await this.version.getLatestVersion();
        const versionString = `${version.major}.${version.minor}.${version.revision}`;
        const msgTitle = 'Version ' + versionString + ' Available';
        const msgSub = 'This version no longer supported';
        const msgBody = 'Please visit the App Store to update to the newest version';

        await this.alert.newBuildAvailable(msgTitle, msgSub, msgBody, false);
      } else {
        await this.alert.dismissNewBuild();
      }
    });
    this.subscriptions.add(force);
  }

  //#endregion ////////////////////////////////

  //#region ////////// Navigation /////////////
  goToAdmin() {
    this.route.navigate(['private', 'options']);
  }
  goToClients() {
    this.route.navigate(['private', 'client-list']);
  }
  goToAddClients() {
    this.route.navigate(['private', 'client-add']);
  }
  goToDbSettings() {
    this.route.navigate(['private', 'settings']);
  }
  goToAddNewUser() {
    this.route.navigate(['private', 'users']);
  }
  startNewReport() {
    this.route.navigate(['private', 'select']);
  }
  goToProfile() {
    this.route.navigate(['private', 'profile']);
  }
  goToAssignInspections() {
    this.route.navigate(['private', 'assign']);
  }
  goToMyList() {
    this.route.navigate(['private', 'view']);
  }
  goToChats() {
    this.route.navigate(['private', 'chat-main']);
  }
  goToTimeClock() {
    this.route.navigate(['private', 'timeclock']);
  }
  goToPayroll() {
    this.route.navigate(['private', 'payroll']);
  }
  goToBoatProfile() {
    this.route.navigate(['private', 'boat-profile']);
  }
  goToServiceRequests() {
    this.route.navigate(['private', 'service-requests']);
  }
  async logout() {
    this.ngOnDestroy();
    await this.auth.logout();

    //  this.route.navigateByUrl('/', { replaceUrl: true });
  }

  //#endregion ////////////////////////////

  leaveChat() {
    const event = 'leave';
    const msg = ' has left the group';
    this.chat.addMessage(this.chatId, msg, event).then((res) => {
      this.chat.leaveChat(this.chatId).then((_) => {
        this.route.navigateByUrl('/private/chat-main');
      });
    });
  }

  async kickUser(data) {
    //   console.log('KickUser Event', e);
    console.log('KickUser Data', data);
    console.log(this.chatUsers);
    // await this.chat.kickUser(data);
  }

  async backConfirm() {
    const alert = await this.alertC.create({
      header: 'Confirm',
      subHeader: '',
      message: 'Do you want to leave the chat?',
      buttons: [
        {
          text: 'Back',
        },
        {
          text: 'Leave',
          handler: () => {
            this.leaveChat();
          },
        },
      ],
    });

    await alert.present();
  }

  async confirmKick(data) {
    if(this.isDev) {
      const alert = await this.alertC.create({
        header: 'Confirm',
        subHeader: '',
        message: 'Are you sure you want to kick ' + data.user + '?',
        buttons: [
          {
            text: 'Back',
          },
          {
            text: 'Kick',
            handler: () => {
              this.kickUser(data);
            },
          },
        ],
      });
  
      await alert.present();
    } else {
      console.log('Do nothing')
    }

  }

  async consoleLogs() {
      const modal = await this.viewConsole.create({
        component: ConsoleViewPage,
      });
      modal.present();
  
      const { data, role } = await modal.onWillDismiss();
  
      // if (role === 'confirm') {
      //   this.message = `Hello, ${data}!`;
      // }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    if (Capacitor.isNativePlatform() === true) {
      console.log('this platform is:', this.platform.is('ios'));
      this.push.removeAllListeners();
    }

    //this.ngOnInit();
  }
}
