import { Injectable } from '@angular/core';
import { addDoc, arrayRemove, arrayUnion, collection, collectionData, doc, docData, documentId, Firestore, getCountFromServer, getDocs, getDoc, limit, orderBy, query, serverTimestamp, updateDoc, where, writeBatch, deleteDoc,} from '@angular/fire/firestore';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { getDownloadURL, ref, Storage, uploadString, } from '@angular/fire/storage';
import { AuthService } from '../../authentication/auth.service';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { ImageService } from '../../images/image.service';
import { ClientService } from '../clients/client.service';
import { UserService } from '../users/user.service';
import { DataService } from '../../device/local/data.service';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { NewMessageBadge } from 'src/app/interfaces/interface';
import { Logger } from '../../logs/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  groupName: string;
  logout$: Subject<boolean> = new Subject<boolean>();
  userId;
  userDisplay;

  constructor(
    private firestore: Firestore,
    private auth: AuthService,
    private storage: Storage,
    private img: ImageService,
    private firebaseAuth: Auth,
    private client: ClientService,
    private userSvc: UserService,
    private localData: DataService,
    private logger: Logger
  ) {
    onAuthStateChanged(this.firebaseAuth, (user) => {
      if (!user) {
        this.logout$.next(true);
      }
    });
  }

  private refresh = new BehaviorSubject<boolean>(false);
  refreshPage = this.refresh.asObservable();

  public refreshChat(refresh: boolean) {
    this.refresh.next(refresh);
  }

  async startChat(user) {
    console.warn('started Chat', user);

    const userDisplay = await this.auth.getUserDisplayName();
    const userId = this.auth.getUserId();
    const userEmail = this.auth.getUserEmail();
    const sender = false;
    if (user.user === 'employee') {
      this.groupName = user.display;
    } else if (user.user === 'client') {
      this.groupName = user.boat;
    }

    const chatUsers = [
      { id: userId, email: userEmail, display: userDisplay, sender: sender },
      // { id: testId, email: testEmail, display: testDisplay },
      { id: user.id, email: user.email, display: user.display, sender: sender },
    ];

    return this.addChat(chatUsers, this.groupName);
  }

  async startChatClient(data?) {
    console.log('startChatClient, what is data?', data);
    var userId;
    var clientId;
    var userDisplay;
    var userEmail;
    var boatImg;

    if (data) {
      userId = this.auth.getUserId();
      clientId = data.id;
      userDisplay = data.fullName;
      userEmail = data.email;
      boatImg = await this.client.getImgUrl(clientId);
      console.log('is there a boat img', boatImg);

      const userBoat = await this.client
        .getClientBoatById(clientId)
        .then((res) => {
          this.groupName = res;
        });
    } else {
      userId = this.auth.getUserId();
      clientId = await this.userSvc.getUserClientId(userId);
      userDisplay = await this.userSvc.getUserDisplayName(userId);
      userEmail = this.auth.getUserEmail();
      boatImg = await this.client.getImgUrl(clientId.id1);
      console.log('is there a boat img', boatImg);

      const userBoat = await this.client
        .getClientBoatById(clientId.id1)
        .then((res) => {
          this.groupName = res;
        });
    }

    const sender = false;
    const owner = await this.userSvc.getOwnerForChat();

    const chatUsers = [
      { id: userId, email: userEmail, display: userDisplay, sender: sender },
      // { id: testId, email: testEmail, display: testDisplay },
      {
        id: owner.id,
        email: owner.email,
        display: owner.display,
        sender: sender,
      },
    ];

    return this.addChatClient(chatUsers, this.groupName, boatImg);
  }

  startGroup(name, users: []) {
    const userId = this.auth.getUserId();
    const userEmail = this.auth.getUserEmail();
    const cleanedUsers = users.map((usr: any) => {
      return {
        id: usr.id,
        email: usr.email,
      };
    });

    const chatUsers = [{ id: userId, email: userEmail }, ...cleanedUsers];

    return this.addChat(chatUsers, name);
  }

  private async addChat(chatUsers, name) {
    console.warn('what is data coming to addchat', chatUsers, name);
    const chatsRef = collection(this.firestore, 'chats');
    const chat = {
      users: chatUsers,
      name: name,
      sentTime: new Date(),
    };

    const res = await addDoc(chatsRef, chat);
    const groupId = res.id;
    const promises = [];

    for (let user of chatUsers) {
      const numberOfChats = await this.getNumberOfChatIds(user.id);
      console.log(
        'ChatService: Total Chats:',
        numberOfChats,
        'for user:',
        user.id
      );
      if (numberOfChats >= 40 && numberOfChats <= 49) {
        const userChatsRef = doc(this.firestore, `users/${user.id}`);
        const update = updateDoc(userChatsRef, {
          chats5: arrayUnion(groupId),
        });
        promises.push(update);
      } else if (numberOfChats >= 30 && numberOfChats <= 39) {
        const userChatsRef = doc(this.firestore, `users/${user.id}`);
        const update = updateDoc(userChatsRef, {
          chats4: arrayUnion(groupId),
        });
        promises.push(update);
      } else if (numberOfChats >= 20 && numberOfChats <= 29) {
        const userChatsRef = doc(this.firestore, `users/${user.id}`);
        const update = updateDoc(userChatsRef, {
          chats3: arrayUnion(groupId),
        });
        promises.push(update);
      } else if (numberOfChats >= 10 && numberOfChats <= 19) {
        const userChatsRef = doc(this.firestore, `users/${user.id}`);
        const update = updateDoc(userChatsRef, {
          chats2: arrayUnion(groupId),
        });
        promises.push(update);
      } else {
        console.log('ChatService: Normal Chats');
        const userChatsRef = doc(this.firestore, `users/${user.id}`);
        const update = updateDoc(userChatsRef, {
          chats: arrayUnion(groupId),
        });
        promises.push(update);
      }
    }
    return await Promise.all(promises);
  }

  private addChatClient(chatUsers, name, image) {
    console.warn('what is data coming to addchat', chatUsers, name);
    const chatsRef = collection(this.firestore, 'chats');
    const chat = {
      users: chatUsers,
      name: name,
      sentTime: new Date(),
      imageUrl: image,
    };

    return addDoc(chatsRef, chat).then(async (res) => {
      const groupId = res.id;
      const promises = [];
      await this.setClientChatId(res.id);

      for (let user of chatUsers) {
        const userChatsRef = doc(this.firestore, `users/${user.id}`);
        const numberOfChats = await this.getNumberOfChatIds(user.id);

        let chatsField = 'chats';
        if (numberOfChats >= 10 && numberOfChats <= 19) {
          chatsField = 'chats2';
        } else if (numberOfChats >= 20 && numberOfChats <= 29) {
          chatsField = 'chats3';
        } else if (numberOfChats >= 30 && numberOfChats <= 39) {
          chatsField = 'chats4';
        } else if (numberOfChats >= 40 && numberOfChats <= 49) {
          chatsField = 'chats5';
        }

        const update = updateDoc(userChatsRef, {
          [chatsField]: arrayUnion(groupId),
        });

        promises.push(update);
      }
      return await Promise.all(promises);
    });
  }

  async setClientChatId(chatId) {
    const id = this.auth.getUserId();
    const ref = doc(this.firestore, `users/${id}`);
    await updateDoc(ref, {
      clientChatId: chatId,
    });
  }

  async addUserToChat(chatId, user) {
    const chatRef = doc(this.firestore, `chats/${chatId}`);
    const userRef = doc(this.firestore, `users/${user.user.id}`);
    try {
      await updateDoc(chatRef, {
        users: arrayUnion({
          display: user.user.display,
          email: user.user.email,
          id: user.user.id,
        }),
      });
      const numberOfChats = await this.getNumberOfChatIds(user.user.id);

      let chatsField = 'chats';
      if (numberOfChats >= 10 && numberOfChats <= 19) {
        chatsField = 'chats2';
      } else if (numberOfChats >= 20 && numberOfChats <= 29) {
        chatsField = 'chats3';
      } else if (numberOfChats >= 30 && numberOfChats <= 39) {
        chatsField = 'chats4';
      } else if (numberOfChats >= 40 && numberOfChats <= 49) {
        chatsField = 'chats5';
      }
      console.log('chatsField', chatsField);
      await updateDoc(userRef, {
        [chatsField]: arrayUnion(chatId),
      });
    } catch (error) {
      console.error(error);
    }
  }

  async addMessage(chatId, msg, users, event?, addId?) {
    var msgToShow;
    const myUserId = this.auth.getUserId();

    if (addId) {
      console.log('addMessage Service there is an addId', addId);
      this.userId = addId.user.id;
      this.userDisplay = addId.user.display;
    } else {
      this.userId = this.auth.getUserId();
      this.userDisplay = await this.auth.getUserDisplayName();
    }

    var usersInChat = {};
    users.forEach((person) => {
      usersInChat[person.id] = person.id === myUserId;
    });

    console.warn('USERINCHAT READ STATUS', usersInChat);

    const array = [
      { id: 1, name: true },
      { id: 2, name: true },
      { id: 3, name: false },
    ];
    const finalObj = {};

    if (event) {
      msgToShow = this.userDisplay + msg;
      const messages = collection(this.firestore, `chats/${chatId}/messages`);
      return addDoc(messages, {
        from: this.userId,
        display: this.userDisplay,
        msg: msgToShow,
        event: event,
        createdAt: serverTimestamp(),
        readStatus: usersInChat,
      });
    } else {
      msgToShow = msg;
      const messages = collection(this.firestore, `chats/${chatId}/messages`);
      return addDoc(messages, {
        from: this.userId,
        display: this.userDisplay,
        msg: msgToShow,
        createdAt: serverTimestamp(),
        readStatus: usersInChat,
      });
    }
  }

  async updateLastChat(chatId, msg) {
    const userDisplay = await this.auth.getUserDisplayName();
    const chatsRef = doc(this.firestore, `chats/${chatId}`);
    return await updateDoc(chatsRef, {
      msgFrom: userDisplay,
      sentTime: serverTimestamp(),
      lastMessage: msg,
    });
  }

  async hasUserReadMessage(
    chatRoomId: string,
    messageId: string,
    userId: string
  ): Promise<boolean> {
    //  await this.markUnreadMessagesAsRead(chatRoomId, userId);
    try {
      const messageRef = doc(
        this.firestore,
        `chats/${chatRoomId}/messages/${messageId}`
      );
      const docSnap = await getDoc(messageRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        const readStatus = data.readStatus;
        if (readStatus && readStatus[userId]) {
          return readStatus[userId]; // Returns true or false indicating if the user has read the message
        }
      }
      return false; // Default value if message or read status does not exist
    } catch (error) {
      console.error('Error checking read status:', error);
      throw error;
    }
  }

  async markUnreadMessagesAsRead(chatRoomId: string, userId: string) {
    const messageRef = collection(
      this.firestore,
      `chats/${chatRoomId}/messages`
    );
    const q = query(messageRef, where(`readStatus.${userId}`, '==', false));
    const dateTime = new Date();
    const batch = writeBatch(this.firestore);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      batch.update(doc.ref, {
        [`readStatus.${userId}`]: true,
        [`readStatus.${userId}readAt`]: dateTime,
      });
      // doc.data() is never undefined for query doc snapshots
      console.log('updated this: ', doc.id, ' => ', doc.data());
    });

    await batch.commit();
  }

  async checkForUnreadMessages(chatRoomId: string, userId: string) {
    const messageRef = collection(
      this.firestore,
      `chats/${chatRoomId}/messages`
    );
    const q = query(messageRef, where(`readStatus.${userId}`, '==', false));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log('unread message', doc.id, doc.data());
    });
    //console.log(querySnapshot);

    return !querySnapshot.empty;
  }

  async totalUnreadMessages(userId: string): Promise<Observable<NewMessageBadge>> {
    let unreadCount = 0;
    const chatRef = collection(this.firestore, 'chats');
    const querySnapshot = await getDocs(chatRef);

    const checkPromises = querySnapshot.docs.map(async (doc) => {
      const check = await this.checkForUnreadMessages(doc.id, userId);

      if (check) {
        unreadCount += 1;
      }
    });

    await Promise.all(checkPromises);
    this.localData.unreadMessageInfo(unreadCount);
    const newMessageBadge: NewMessageBadge = {
      // Customize the properties of NewMessageBadge based on your needs
      count: unreadCount,
      // ...other properties
    };

    return of(newMessageBadge);
  }

  async addFileMsg(base64, chatId) {
    const userId = this.auth.getUserId();
    const userDisplay = await this.auth.getUserDisplayName();
    const pic = await this.img.compressImage(base64);
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}-${userId}.jpeg`;

    const storageRef = ref(this.storage, `chat Files/${fileName}`);
    const uploadResult = await uploadString(storageRef, pic, 'data_url', {
      contentType: 'image/jpeg',
    });

    const url = await getDownloadURL(uploadResult.ref);

    const messagesRef = collection(this.firestore, `chats/${chatId}/messages`);
    return addDoc(messagesRef, {
      from: userId,
      display: userDisplay,
      file: url,
      createdAt: serverTimestamp(),
    });
  }

  async leaveChat(chatId: string): Promise<void> {
    const userId = this.auth.getUserId();
    const userEmail = this.auth.getUserEmail();
    console.log('userid and useremail', userId, userEmail);

    const chatRef = doc(this.firestore, `chats/${chatId}`);
    await updateDoc(chatRef, {
      users: arrayRemove({ id: userId, email: userEmail }),
    });

    const userRef = doc(this.firestore, `users/${userId}`);
    await updateDoc(userRef, {
      chats: arrayRemove(chatId),
    });

    this.refreshChat(true);
  }

  async findAndDeleteChat(chat) {
    var chatId = chat.id;
    const users = chat.users;

    if (users.length <= 2) {
      users.forEach(async (user) => {
        const userId = user.id;
        const ref = doc(this.firestore, `users/${userId}`);
        const docSnap = await getDoc(ref);

        if (docSnap.exists()) {
          console.log('Document data:', docSnap.data());
          const data = docSnap.data();
          console.log(
            '🚀 ~ file: chat.service.ts:503 ~ findAndDeleteChat ~ data:',
            data
          );
          for (const key in data) {
            if (Array.isArray(data[key])) {
              const index = data[key].indexOf(chatId);
              if (index !== -1) {
                const userRef = doc(this.firestore, `users/${userId}`);
                await updateDoc(userRef, {
                  [key]: arrayRemove(chatId),
                });
              }
            }
          }
        } else {
          console.log('No such document!');
        }
      });

      await deleteDoc(doc(this.firestore, 'chats', `${chatId}`));
    } else {
      this.leaveChat(chatId);
    }
  }

  async kickUser(data): Promise<void> {
    const chatId: string = data.chatId;
    console.log('Chat Id:', chatId);

    const userId: string = data.id;
    console.log('User Id: ', userId);

    const email: string = data.email;
    console.log('UserEmail', email);

    const chatRef = doc(this.firestore, `chats/${chatId}`);
    await updateDoc(chatRef, {
      users: arrayRemove({ id: userId, email: email }),
    });

    const userRef = doc(this.firestore, `users/${userId}`);
    await updateDoc(userRef, {
      chats: arrayRemove(chatId),
    });
    //  await this.removeUser(data);

    // const userRef = doc(this.firestore, `users/${userId}`);
    // await updateDoc(userRef, {
    //   chats: arrayRemove(chatId),
    // }).then(res => { console.log('removed chat for ', userId)});

    //   this.refreshChat(true);
  }

  async removeUser(data) {
    console.log('Remove User called', data.id);
    const userRef = doc(this.firestore, `users/${data.id}`);
    await updateDoc(userRef, {
      chats: arrayRemove(data.chatId),
    }).then((res) => {
      console.log('removed chat for ', data.id);
      //   this.refreshChat(true);
    });
  }

  //#region  /////// Number of Chats logic ////////

  async getNumberOfChatIds(user?) {
    console.log('called Get number of ChatIds for user ', user);
    let userId;

    if (!user) {
      userId = this.auth.getUserId();
    } else {
      userId = user.trim();
    }

    const ref = doc(this.firestore, `users/${userId}`);
    const docSnap = await getDoc(ref);

    if (!docSnap.exists()) {
      console.error('No Document Data found in getNumberOfChatIds');
      return;
    }

    const data = docSnap.data();
    const chatLength = data['chats'].length;
    console.log(data);
    const number = this.processData(data);
    console.log('number of chats', number);

    return number;
    // if (chatLength !== 10) {
    //   return chatLength;
    // }

    // let total = chatLength;
    // let chatCount = 2;

    // while (chatCount <= 5) {
    //   var extraChats = 0;
    //   const chatFunctionName = `getNumberOfChat${chatCount}Ids`;
    //   const chatFunction = this[chatFunctionName];
    //   if (typeof chatFunction === 'function') {
    //     const chat = await chatFunction.call(this, user);

    //     if (chat !== 10) {
    //       extraChats = chat;
    //         console.log('extra chats', chat, extraChats);
    //       break;
    //     }
    //     chatCount++;
    //   } else {
    //     console.error(`Function ${chatFunctionName} is not defined.`);
    //     break;
    //   }
    //   total = extraChats + total;
    // }
    // //  console.warn('TOTAL NUMBER OF CHATS', total + extraChats);
    // return total + extraChats;
  }

  processData(data) {
    let totalLength = 0;

    if (data.chats && Array.isArray(data.chats)) {
        totalLength += data.chats.length;
    }

    if (data.chats2 && Array.isArray(data.chats2)) {
        totalLength += data.chats2.length;
    }

    if (data.chats3 && Array.isArray(data.chats3)) {
        totalLength += data.chats3.length;
    }

    if (data.chats4 && Array.isArray(data.chats4)) {
        totalLength += data.chats4.length;
    }

    if (data.chats5 && Array.isArray(data.chats5)) {
      totalLength += data.chats5.length;
  }

    return totalLength;
  }
  private async getNumberOfChat2Ids(user?) {
    console.log('getnumberof Chat 2', user);
    if (!user) {
      const userId = this.auth.getUserId();
      console.log('Got User id calling firebase', userId);
      const ref = doc(this.firestore, `users/${userId}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats2) {
          return data['chats2'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChatIds');
      }
    } else {
      const ref = doc(this.firestore, `users/${user}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats2) {
          return data['chats2'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChat2Ids');
        return 0;
      }
    }
  }

  private async getNumberOfChat3Ids(user?) {
    console.log('Did we get user in chat 3?', user);
    if (!user) {
      const userId = this.auth.getUserId();
      console.log('Got User id calling firebase', userId);
      const ref = doc(this.firestore, `users/${userId}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats3) {
          return data['chats3'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChatIds3');
      }
    } else {
      const ref = doc(this.firestore, `users/${user}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats3) {
          return data['chats3'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChat3Ids');
        return 0;
      }
    }
  }

  private async getNumberOfChat4Ids(user?) {
    if (!user) {
      const userId = this.auth.getUserId();
      const ref = doc(this.firestore, `users/${userId}`);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats4) {
          return data['chats4'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChatIds4');
      }
    } else {
      const ref = doc(this.firestore, `users/${user}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats4) {
          return data['chats4'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChat4Ids');
        return 0;
      }
    }
  }

  private async getNumberOfChat5Ids(user?) {
    if (!user) {
      const userId = this.auth.getUserId();
      const ref = doc(this.firestore, `users/${userId}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats5) {
          return data['chats5'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChatIds5');
      }
    } else {
      const ref = doc(this.firestore, `users/${user}`);
      const docSnap = await getDoc(ref);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.chats5) {
          return data['chats5'].length;
        } else {
          return 0;
        }
      } else {
        console.error('No Document Data found in getNumberOfChat5Ids');
        return 0;
      }
    }
  }

  //#endregion /////////////////////////////

  getAllUsers() {
    const userId = this.auth.getUserId();
    const userDisplay = this.auth.getUserDisplayName();
    const usersRef = collection(this.firestore, 'users');

    const q = query(usersRef, where('accountType', '==', 'employee'));
    return collectionData(q, { idField: 'id' }).pipe(takeUntil(this.logout$));
  }

  // getUserChats() {
  //   const userId = this.auth.getUserId();
  //   const userRef = doc(this.firestore, `users/${userId}`);

  //   return docData(userRef).pipe(
  //     switchMap((data) => {
  //       console.warn(data);
  //       let userChats = [];

  //       for (let i = 5; i >= 2; i--) {
  //         const chatsKey = `chats${i}`;
  //         if (data[chatsKey]) {
  //           userChats = [...data[chatsKey], ...userChats];
  //         }
  //       }

  //       if (data.chats) {
  //         userChats = [...data.chats, ...userChats];
  //       }

  //        console.warn('USER CHATS ', userChats, userChats.length);
  //       const chunkedArray = this.chunkArray(userChats, 29);
  //       console.log('chunkedArray',chunkedArray)
  //       const array1 = chunkedArray[0];
  //       const array2 = chunkedArray[1];
  //       console.log('array1', array1);
  //       console.log('array2', array2);

  //       const chatsRef = collection(this.firestore, 'chats');
      
  //       if(chunkedArray[0].length <= 29) {
  //         var final = [];
  //         const q = query(chatsRef, where(documentId(), 'in', chunkedArray[0]));
  //         const docs = this.queryCollection(chunkedArray[0]).subscribe(res => {
  //           final=res;
  //           console.log('Inside subscribe:',res);
  //         });

  //         this.queryCollection(chunkedArray[1]).subscribe((res) => {
  //           const combinedArray = final.concat(res);
  //           console.log('Inside subscribe2', combinedArray)
  //           return final;
  //         });
  //         // const docs = collectionData(q, { idField: 'id' });
  //         console.log(docs);
  //         // return collectionData(q, { idField: 'id' });
  //         // final.push(collectionData(q, { idField: 'id' }));

  //         // const q1 = query(chatsRef, where(documentId(), 'in', chunkedArray[1]));
  //         // final.push(collectionData(q, { idField: 'id' }));
  //         //console.log(final);

  //         return final;
  //       }

  //       // if (userChats.length > 0) {
  //       //   //     console.warn(userChats);
  //       //   const q = query(chatsRef, where(documentId(), 'in', userChats));
  //       //   return collectionData(q, { idField: 'id' });
  //       // } else {
  //       //   console.log('data is 0', userChats.length);
  //       // }
  //     }),
  //     catchError((err) => EMPTY),
  //     takeUntil(this.logout$)
  //   );
  // }
  
  getUserChats() {
    const userId = this.auth.getUserId();
    const userRef = doc(this.firestore, `users/${userId}`);
  
    return docData(userRef).pipe(
      switchMap((data) => {
        console.warn(data);
        let userChats = [];
  
        for (let i = 5; i >= 2; i--) {
          const chatsKey = `chats${i}`;
          if (data[chatsKey]) {
            userChats = [...data[chatsKey], ...userChats];
          }
        }
  
        if (data.chats) {
          userChats = [...data.chats, ...userChats];
        }
  
        console.warn('USER CHATS ', userChats, userChats.length);
  
        const chunkedArray = this.chunkArray(userChats, 29);
        console.log('chunkedArray', chunkedArray);
        const observables = chunkedArray.map(chunk => {
          const chatsRef = collection(this.firestore, 'chats');
          const q = query(chatsRef, where(documentId(), 'in', chunk));
          return collectionData(q, { idField: 'id' });
        });
  
        return combineLatest(observables).pipe(
          map(chats => {
            return chats.reduce((combined, current) => {
              return combined.concat(current);
            }, []);
          })
        );
      }),
      catchError((err) => EMPTY),
      takeUntil(this.logout$)
    );
  }
  
  chunkArray(array, chunkSize) {
    console.log('array lenght', array.length);
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    console.log('how many chunks returned:', chunks.length);
    return chunks;
  }

  queryCollection(array) {
    const chatsRef = collection(this.firestore, 'chats');
    const q = query(chatsRef, where(documentId(), 'in', array));
    return collectionData(q, { idField: 'id' });
  }

  getMoreChats() {
    console.warn('called getMore');
    const userId = this.auth.getUserId();
    const userRef = doc(this.firestore, `users/${userId}`);
    return docData(userRef).pipe(
      switchMap((data) => {
        console.log(data);
        const userChats = data.chats2;
        const chatsRef = collection(this.firestore, 'chats');
        if (data.chats2.length != 0) {
          console.warn(userChats);
          const q = query(chatsRef, where(documentId(), 'in', userChats));
          console.log('q', collectionData(q, { idField: 'id' }));
          return collectionData(q, { idField: 'id' });
        } else {
          console.log('data is  0', data.chats.length);
        }
      }),
      catchError((err) => EMPTY),
      takeUntil(this.logout$)
    );
  }

  getMoreChats2() {
    console.warn('called getMore');
    const userId = this.auth.getUserId();
    const userRef = doc(this.firestore, `users/${userId}`);
    return docData(userRef).pipe(
      switchMap((data) => {
        console.log(data);
        const userChats = data.chats3;
        const chatsRef = collection(this.firestore, 'chats');
        if (data.chats3.length != 0) {
          console.warn(userChats);
          const q = query(chatsRef, where(documentId(), 'in', userChats));
          console.log('q', collectionData(q, { idField: 'id' }));
          return collectionData(q, { idField: 'id' });
        } else {
          console.log('data is  0', data.chats.length);
        }
      }),
      catchError((err) => EMPTY),
      takeUntil(this.logout$)
    );
  }

  getMoreChats3() {
    console.warn('called getMore');
    const userId = this.auth.getUserId();
    const userRef = doc(this.firestore, `users/${userId}`);
    return docData(userRef).pipe(
      switchMap((data) => {
        console.log(data);
        const userChats = data.chats4;
        const chatsRef = collection(this.firestore, 'chats');
        if (data.chats4.length != 0) {
          console.warn(userChats);
          const q = query(chatsRef, where(documentId(), 'in', userChats));
          console.log('q', collectionData(q, { idField: 'id' }));
          return collectionData(q, { idField: 'id' });
        } else {
          console.log('data is  0', data.chats.length);
        }
      }),
      catchError((err) => EMPTY),
      takeUntil(this.logout$)
    );
  }

  getMoreChats4() {
    console.warn('called getMore');
    const userId = this.auth.getUserId();
    const userRef = doc(this.firestore, `users/${userId}`);
    return docData(userRef).pipe(
      switchMap((data) => {
        console.log(data);
        const userChats = data.chats5;
        const chatsRef = collection(this.firestore, 'chats');
        if (data.chats5.length != 0) {
          console.warn(userChats);
          const q = query(chatsRef, where(documentId(), 'in', userChats));
          console.log('q', collectionData(q, { idField: 'id' }));
          return collectionData(q, { idField: 'id' });
        } else {
          console.log('data is  0', data.chats.length);
        }
      }),
      catchError((err) => EMPTY),
      takeUntil(this.logout$)
    );
  }

  async getLatestChat(data: any[]): Promise<string> {
    console.log('calling getLatestChat with data:', data);
    const chat = collection(this.firestore, `chats/${data[0].id}/messages`);
    const q = query(chat, orderBy('createdAt', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);

    let latestChat: string;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(data);
      latestChat = data.msg;
    });

    return latestChat;
  }

  async getLatestChat1(chatId) {
    const chat = collection(this.firestore, `chats/${chatId}/messages`);
    const q = query(chat, orderBy('createdAt', 'desc'), limit(1));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log(data);
      return data;
    });
  }

  getChatInfo(chatId) {
    console.log('called get chat info with id', chatId);
    const chat = doc(this.firestore, `chats/${chatId}`);
    const x = docData(chat);
    return docData(chat).pipe(takeUntil(this.logout$));
  }

  getChatMessages(chatId) {
    const messages = collection(this.firestore, `chats/${chatId}/messages`);
    const q = query(messages, orderBy('createdAt'));
    return collectionData(q, { idField: 'id' }).pipe(takeUntil(this.logout$));
  }

  async doesChatAlreadyExist(boat) {
    try {
      const q = query(
        collection(this.firestore, 'chats'),
        where('name', '==', boat)
      );

      const snapShot = await getCountFromServer(q);
      const num = snapShot.data().count;

      return num !== 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
