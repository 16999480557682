import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Logger } from '../../logs/logger.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  percentage: number;
  constructor( private logger: Logger) {}

  public async getDeviceInfo() {
    const info = await Device.getInfo();

    console.log('getDeviceInfo Service : ', info);
    return info;
  }

  public async getDevicePlatform() {
    const info = await Device.getInfo();
    return info.platform;
  }

  public async getDeviceModel() {
    const info = await Device.getInfo();
    return info.model;
  }

  public async getDeviceId() {
    const info = await Device.getId();
    return info.identifier;
  }

  public async getBatteryInfo() {
    const info = await Device.getBatteryInfo();
    this.percentage = info.batteryLevel * 100;
    const percent = this.percentage.toFixed(2) + '%';
    const data = { batteryLevel: percent, isCharging: info.isCharging };

    console.log('Battery Information : ', info);
    console.log('Battery Information', data);
    return info;
  }
}
