import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
// import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';

const packageInfo = require('../package.json');


if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryUrl,
  release: `${packageInfo.version}`,
  tracesSampleRate: 1.0,
  integrations: [
    // new BrowserTracing({
    //   tracingOrigins: ['localhost', 'https://proCapdb.web.app'],
    //   routingInstrumentation: Sentry.routingInstrumentation,
    // }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

  // defineCustomElements(window);
