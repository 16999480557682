import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  async setKey(key: string, value: string) {
    await Preferences.set({ key: key, value: value });
    console.debug('setKey Service for ', key);
  }

  async getKey(key: string) {
    return await Preferences.get({ key: key }).then((results) => {
      const data = results;
      console.debug('Returned data for ', key);
      return data.value;
    });
  }

  async removeKey(key: string) {
    await Preferences.remove({ key: key });
  }

  async getAllKeys() {
    return await Preferences.keys();
  }

  async clearKeys() {
    await Preferences.clear();
  }
}
