import { Injectable, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import * as Sentry from '@sentry/angular-ivy';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { DataService } from '../device/local/data.service';
//import { file } from 'googleapis/build/src/apis/file';

/**
 * The possible log levels.
 */
export enum LogLevel {
  Error,
  Warning,
  Info,
  Debug,
}

@Injectable()
export class Logger {

  constructor(private ngx: NGXLogger, private localData: DataService) {}


  getStringValue(value: any): string {
    console.log('what are values passed? ', value);
    if (value.length > 1) {
    return JSON.stringify(value);
    } else {
      return JSON.stringify(value);
    }
  }

  private async deleteSecretFile()  {
    const filePath = 'test/logs.txt';
    await Filesystem.deleteFile({
      path: filePath,
      directory: Directory.Documents,
    });
  };

  public async logFileInit() {
    const filePath = 'test/logs.txt';
    await this.deleteSecretFile();

        await Filesystem.writeFile({
          path: filePath,
          data: 'Logging Started' + '\n',
          directory: Directory.Documents,
          encoding: Encoding.UTF8,
          recursive: true,
        });
  }

  private async appendFile(...objects) {
    try {
      const user = this.localData.userLoggedIn.displayName;
      const capturedLogs = [];
  
      const originalConsoleLog = console.log;
      console.log = function (...args) {
       // console.log(typeof args);
        const logText = args.map(arg => {
          
          if (typeof arg === 'object') {
            if (Array.isArray(arg)) {
            
              return arg.map(item => JSON.stringify(item)).join(' ');
            } else {
              return JSON.stringify(arg);
            }
          }
       //   console.log(arg);
          return arg;
        }).join(' ');
        
        capturedLogs.push(logText); // Only push the log text without array brackets
  
        originalConsoleLog.apply(console, args);
      };
  
      console.log(objects);
      console.log = originalConsoleLog;
   //   console.log('captured logs', capturedLogs);
      const logsText = capturedLogs.join('\n'); // Now logsText contains plain log text
    // console.log('this is logsText: ', logsText)
      const clean = this.removeChars(logsText);
      
      const date = new Date().toLocaleDateString();
      const time = new Date().toLocaleTimeString();
      const logEntry = {
        user: user,
        date: date,
        time: time,
        log: clean
      };
  
      this.localData.addLog(logEntry);
      const length = this.localData.getLogLength();
      //console.warn('Logs length', length);
  
      if (length > 20) {
        // Append logs to the file
        const logs = this.localData.capturedLogs;
        //console.log(logs);
        const filePath = 'test/logs.txt';
        // logs.forEach(async (log) => {
        //   await Filesystem.appendFile({
        //     path: filePath,
        //     data: log + '\n',
        //     directory: Directory.Documents, // Choose the appropriate directory
        //     encoding: Encoding.UTF8,
        //   });
        // })
  
        //this.localData.clearLog();
      } else {
      //  console.log('Added log');
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  removeChars(inputString) {
    console.info('input string:' , inputString)
    const sanitizedString = inputString.replace(/["\[\]]/g, '');
    return sanitizedString;
  }
  
  
  

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  public async debug(...objects: any[]) {
    if(isDevMode()) {
      console.log(console.log, objects);
    this.log(console.log, LogLevel.Debug, objects);
    }
    this.appendFile(objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.info().
   */
  public info(...objects: any[]) {
    this.log(console.info, LogLevel.Info, objects);
    // this.appendFile(objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.warn().
   */
  public warn(...objects: any[]) {
    this.log(console.warn, LogLevel.Warning, objects);
    this.appendFile(objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.error().
   */
  public error(...objects: any[]) {
    this.log(console.error, LogLevel.Error, objects);
    Sentry.captureException(objects);
    this.appendFile(objects);
  }

  private log(func: Function, level: LogLevel, objects: any[]) {
    const env = environment.production;
    // console.log('objects : ', objects);
    if (env !== true || (env === true && level === LogLevel.Error)) {
      func.apply(console, objects);
      this.appendFile(objects);
    } else if (env === true && level === LogLevel.Error) {
      this.ngx.error(objects);
    }
  }
}