import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Firestore, collection, collectionData, doc, docData, addDoc, deleteDoc, updateDoc, query, getDoc, where, getDocs } from '@angular/fire/firestore';
import { getDownloadURL, getStorage, ref, Storage, uploadBytes } from '@angular/fire/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { GpsLog, Email, Lakes, Marinas, Settings, ReportCollection } from 'src/app/interfaces/interface';
import { Logger } from '../../logs/logger.service';


@Injectable({
  providedIn: 'root',
})
export class DbDataService {
  pdfReports = [];
  folders = [];
  temp = [];
  cloudFiles = [];
  subFolders = [];
  tempF = [];
  _ = require('lodash');

  private progressBar = new BehaviorSubject<number>(0);
  pbar = this.progressBar.asObservable();

  // private pBlob = new BehaviorSubject<boolean>(false);
  // photoBlob = this.pBlob.asObservable();

  constructor(
    private firestore: Firestore,
    private storage: Storage,
    private alertController: AlertController,
    private logger: Logger  ) {}

  public updatePbar(refresh: number) {
    this.progressBar.next(refresh);
  }
  // public updatePBlob(refresh: boolean) {
  //   this.pBlob.next(refresh);
  // }

  // #region ////////// Alerts //////////

  async presentAlert(head: string, sub: string, msg: string) {
    const alert = await this.alertController.create({
      header: head,
      subHeader: sub,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();
  }

  addNewData(data: ReportCollection) {
    const ref = collection(this.firestore, 'reports');
    return addDoc(ref, data);
  }
  //#endregion

  // #region  ////////// Settings Services /////////////

  getSettingsValues(): Observable<Settings[]> {
    const ref = collection(this.firestore, 'settings');
    return collectionData(ref, { idField: 'id' }) as Observable<Settings[]>;
  }

  getSettingsValuesById(id: string): Observable<Settings> {
    const ref = doc(this.firestore, `settings/${id}`);
    return docData(ref, { idField: 'id' }) as Observable<Settings>;
  }

  addSettingsValue(setting: Settings) {
    const ref = collection(this.firestore, 'settings');
    return addDoc(ref, setting);
  }

  deleteSettingsValue(setting: Settings) {
    const ref = doc(this.firestore, `settings/${setting.id}`);
    return deleteDoc(ref);
  }

  updateSettingsValue(setting: Settings) {
    const ref = doc(this.firestore, `settings/${setting.id}`);
    return updateDoc(ref, { name: setting.name, value: setting.value });
  }

  async getOwnerValue() {
    const ref = doc(this.firestore, 'settings/n9ZLX2dmLrRnLPQw45gF');
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const owner = docSnap.data();
      return owner.value;
    } else {
      console.info('getEmailToSendTo : No location found');
      return null;
    }
  }

  async getEmailToSendTo() {
    const ref = doc(this.firestore, 'settings/nNuA001yQ6vh4fXaaQyh');
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const email = docSnap.data();
      return email.value;
    } else {
      console.info('getEmailToSendTo : No location found');
      return null;
    }
  }

  async getImageQuality() {
    const ref = doc(this.firestore, 'settings/hKXxm9madOFklYbr7Nzh');
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const i = docSnap.data();
      return i.value;
    } else {
      console.info('getImageQuality : No location found');
      return null;
    }
  }

  async getImageScale() {
    const ref = doc(this.firestore, 'settings/VQQuzfG6O4S34RBjVrnL');
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const i = docSnap.data();
      return i.value;
    } else {
      console.info('getImageScale : No location found');
      return null;
    }
  }

  async getImageOrientation() {
    const ref = doc(this.firestore, 'settings/Ys2I2223vG3tKcyjqlQd');
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const i = docSnap.data();
      return i.value;
    } else {
      console.info('getImageOrientation : No location found');
      return null;
    }
  }

  async getRequestCounter() {
    const ref = doc(this.firestore, 'settings/SYpS5Dn4GYXsLR3fayI6');
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const i = docSnap.data();
      return i.total;
    } else {
      console.info('getRequestCounter : No number found');
      return null;
    }
  }

  updateCounter(number) {
    const ref = doc(this.firestore, 'settings/SYpS5Dn4GYXsLR3fayI6');
    return updateDoc(ref, { total: number });
  }
  // #endregion

  addGps(gps: GpsLog) {
    const ref = collection(this.firestore, 'gps');
    return addDoc(ref, gps);
  }

  updateGps(gps: GpsLog) {
    const ref = doc(this.firestore, `gps/${gps.id}`);
    return updateDoc(ref, { endLat: gps.endLat, endLong: gps.endLong });
  }

  addEmail(email: Email) {
    const notesRef = collection(this.firestore, 'email');
    console.warn('addEmail is processing');
    return addDoc(notesRef, email);
  }

  // #region ////////// Log Services //////////

  addLogToDb(fileName, file) {
    console.debug(file);
    const path = `logs/${fileName}`;
    const storageRef = ref(this.storage, path);

    const metadata = {
      name: 'logtest.txt',
      contentType: 'text/plain',
    };

    // 'file' comes from the Blob or File API
    uploadBytes(storageRef, file, metadata).then((snapshot) => {
      console.debug('File uploaded: ', snapshot);
    });
  }

  // #endregion

  // #region ////////// FileTree Services //////////



  getDownloadURL(path: any) {
    const storage = getStorage();
    getDownloadURL(ref(storage, `${path}/`))
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        console.debug('do ihave a blob file?', xhr.responseType);
        xhr.onload = (event) => {
          const blob = xhr.response;
          console.debug('do ihave a blob file?', blob);
          console.debug('what is the event? ', event);
        };
        xhr.open('GET', url);
        xhr.send();

        // Or inserted into an <img> element
        const img = document.getElementById('myimg');
        img.setAttribute('src', url);
      })
      .catch((error) => {
        console.error('error in getDownloadURL :', error);
        // Handle any errors
      });
  }

 
  // #endregion

  //#region  ////////////// Add - Remove Data ///////////////
  getLakeLocations(): Observable<Lakes[]> {
    const ref = query(collection(this.firestore, 'lakes'));
    return collectionData(ref, { idField: 'id' }) as Observable<Lakes[]>;
  }

  addLake(lake: Lakes) {
    const ref = collection(this.firestore, 'lakes');
    return addDoc(ref, lake);
  }

  deleteLake(id: string) {
    const ref = doc(this.firestore, `lakes/${id}`);
    return deleteDoc(ref);
  }

  getMarinas(): Observable<Marinas[]> {
    const ref = query(collection(this.firestore, 'marina'));
    return collectionData(ref, { idField: 'id' }) as Observable<Marinas[]>;
  }

  addMarina(marina: Marinas) {
    const ref = collection(this.firestore, 'marina');
    return addDoc(ref, marina);
  }

  async updateMarina(marina: Marinas) {
    const ref = doc(this.firestore, 'marina', `${marina.id}`);
    return await updateDoc(ref, {
      area: marina.area,
      lake: marina.lake,
      path: marina.path,
    });
  }

  async updateLake(lake: Lakes) {
    const ref = doc(this.firestore, 'lakes', `${lake.id}`);
    return await updateDoc(ref, {
      location: lake.location,
      latitude: lake.latitude,
      longitude: lake.longitude,
    });
  }

  deleteMarina(id: string) {
    const ref = doc(this.firestore, `marina/${id}`);
    return deleteDoc(ref);
  }

  async getLakeGpsCoords(name: string) {
    const q = query(
      collection(this.firestore, 'lakes'),
      where('location', '==', name)
    );
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.id, ' => ', doc.data());
      data.push(doc.data());
    });
    return data;
  }

  //#endregion //////////
}
