import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../services/authentication/auth.service';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective {
  @Input('appHasRole') roles: string[];
  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this.auth.getUserSubject().subscribe((_) => {
      if (this.auth.hasRoles(this.roles)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
