<ion-header>
  <ion-toolbar>
    <ion-title>Console Logs</ion-title>
    <ion-buttons slot="end">
      <ion-button class="headButtons" (click)="close()" [strong]="true">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">

  <!-- <ion-list>
    <ion-item *ngFor="let i of logs"> {{ i }}</ion-item>
  </ion-list> -->

  <ion-grid>
    <ion-row *ngFor="let i of logs">
      <ion-col>
        <ion-row>{{ i.user }}</ion-row>
      <ion-row>{{ i.date }}</ion-row>
      <ion-row>{{ i.time }}</ion-row>
    </ion-col>
      <ion-col size="8">{{ i.log }}</ion-col>
    </ion-row>
    </ion-grid>

</ion-content>