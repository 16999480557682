import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Logger } from '../logs/logger.service';
import { getMessage } from '../utility/error-messages';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  lockScreen;
  newBuildDisable = false;

  constructor(private alert: AlertController, private route: Router,  private logger: Logger) {}

  async presentAlert(header, sub?, message?) {
    const alert = await this.alert.create({
      header: header,
      subHeader: sub,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async maxAttempts(header, sub, message, allowClose = false) {
    console.log('inactive license screen activated')
    this.lockScreen = await this.alert.create({
      header,
      subHeader: sub,
      message,
      buttons: [],
      backdropDismiss: allowClose,
    });
    await this.lockScreen.present();
  }

  async compressionError(result) {
    const alert = await this.alert.create({
      header: getMessage(2003),
      subHeader: getMessage(2004),
      message: getMessage(2005) + result + getMessage(2006),
      buttons: ['OK'],
    });

    await alert.present();
  }

  async tokenExpired(error) {
    const alert = await this.alert.create({
      header: 'Token Expired',
      subHeader: 'Unable to Authenticate',
      message: 'ERROR: ' + error + '. You must relog in again',
      buttons: ['OK'],
    });
    await alert.present();
  }

  async accountDeleted() {
    const alert = await this.alert.create({
      header: 'Account Delete',
      message: 'Your account has succesfully been deleted',
      buttons: ['OK'],
    });
    await alert.present();
  }

  async backConfirm() {
    const alert = await this.alert.create({
      header: 'Data will be Lost',
      subHeader: 'Starboard Engine Missing Data',
      message:
        'Leaving this page will cause all data to be lost for the report',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.route.navigate(['private', 'engines']);
          },
        },
        {
          text: 'Back',
        },
      ],
    });

    await alert.present();
  }

  async showPortAlert() {
    const alert = await this.alert.create({
      header: 'Alert',
      subHeader: 'Port Engine Missing Data',
      message:
        'There are no hours entered for the Port Engine.  Please enter hours then resubmit',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.route.navigate(['private', 'engines']);
          },
        },
      ],
    });

    await alert.present();
  }

  async showMainAlert() {
    const alert = await this.alert.create({
      header: 'Alert',
      subHeader: 'Main Engine Missing Data',
      message:
        'There are no hours entered for the Main Engine.  Please enter hours then resubmit',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.route.navigate(['private', 'engines']);
          },
        },
      ],
    });

    await alert.present();
  }

  async showStarAlert() {
    const alert = await this.alert.create({
      header: 'Alert',
      subHeader: 'Starboard Engine Missing Data',
      message:
        'There are no hours entered for the Starboard Engine.  Please enter hours then resubmit',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.route.navigate(['private', 'engines']);
          },
        },
      ],
    });

    await alert.present();
  }

  async showGenAlert() {
    const alert = await this.alert.create({
      header: 'Alert',
      subHeader: 'Generator Engine Missing Data',
      message:
        'There are no hours entered for the Generator Engine.  Please enter hours then resubmit',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.route.navigate(['private', 'generator']);
          },
        },
      ],
    });

    await alert.present();
  }

  async showSuccess() {
    const alert = await this.alert.create({
      header: 'Success',
      subHeader: 'Inspection Report Filed',
      message:
        'Your inspection report has been submitted and uploaded successfully',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            this.redirectHome();
          },
        },
      ],
    });

    await alert.present();
  }

  async showPayrollSuccess() {
    const alert = await this.alert.create({
      header: 'Success',
      subHeader: 'Payroll Report Uploaded',
      message:
        'The payroll report has been uploaded and saved successfully',
      buttons: [
        {
          text: 'OK',
        },
      ],
    });

    await alert.present();
  }

  async serverDidNotUpdate(err) {
    const alert = await this.alert.create({
      header: 'Failure to Update',
      subHeader: '',
      message:
        `The client email was not updated on app for customer to sign up via app. Error code: ${err.status} ${err.statusText}`,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });

    await alert.present();
  }

  async downloadPdfError(error) {
    const alert = await this.alert.create({
      header: 'Alert',
      subHeader: 'There was an error downloading the PDF',
      message: error,
      buttons: [
        {
          text: 'OK',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async newVersionAlert(header, sub, message, allowClose = false) {
    const alert = await this.alert.create({
      header,
      subHeader: sub,
      message,
      buttons: [],
      backdropDismiss: allowClose,
    });

    await alert.present();
  }

  async inactiveLicense(header, sub, message, allowClose = false) {
    console.log('inactive license screen activated')
    this.lockScreen = await this.alert.create({
      header,
      subHeader: sub,
      message,
      buttons: [],
      backdropDismiss: allowClose,
    });
    await this.lockScreen.present();
  }

  async dismissLicense() {
    if (this.lockScreen) {
      console.log('lockScreen === true; Dismissing lockScreen')
      await this.alert.dismiss();
    } else {
      console.log('this.lockScreen is false');
    }
  }

  async newBuildAvailable(header, sub, message, allowClose = false) {
    if(this.newBuildDisable === false) {
      this.newBuildDisable = true;
      console.log('newBuildAvailable activated')
      const alert = await this.alert.create({
        header,
        subHeader: sub,
        message,
        buttons: [
          {
            text: 'Go to App Store',
            handler: () => {
              window.open('https://apps.apple.com/app/procap-staff/id1631768110?platform=iphone', '_system', 'location=yes');
              return false;
            },
        }],
        backdropDismiss: allowClose,
      });      
      await alert.present();
    } else {
      console.log('Disable Build screen called again after it is already shown');
    }
  }

  async dismissNewBuild() {
    if (this.newBuildDisable) {
      console.log('this.newBuildDisable is true, so dismiss alert');
      this.newBuildDisable = false;
      await this.alert.dismiss();
    }
  }

  redirectHome() {
    this.route.navigate(['private','home']);
  }
}
