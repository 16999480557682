import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, getAuth, sendPasswordResetEmail, onAuthStateChanged, signInAnonymously, linkWithCredential, EmailAuthProvider, deleteUser, } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { Firestore, doc, setDoc, getDoc, docData } from '@angular/fire/firestore';
import { DataService } from '../device/local/data.service';
import { TitleCasePipe } from '@angular/common';
import { StorageService } from '../device/storage/storage.service';
import { takeUntil } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { AuthUser } from 'src/app/interfaces/interface';
import { UserService } from '../firebase/users/user.service';
import { ClientService } from '../firebase/clients/client.service';
import { AlertService } from '../alert/alert.service';
import { environment } from 'src/environments/environment';
import { Logger } from '../logs/logger.service';

declare var gapi: any;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  captainName: any;
  response = [];
  private currentUser: BehaviorSubject<AuthUser> = new BehaviorSubject(null);
  authStatus = this.currentUser.asObservable();
  currentUserData = null;
  logout$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private titleCase: TitleCasePipe,
    private localData: DataService,
    private route: Router,
    private alert: AlertService,
    private loadingController: LoadingController,
    private pref: StorageService,
    private userSvc: UserService,
    private clientSvc: ClientService,
    private logger: Logger
  ) {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.localData.userLoggedIn.displayName = user.displayName;
        const userDoc = doc(this.firestore, `users/${user.uid}`);
        docData(userDoc, { idField: 'id' })
          .pipe(takeUntil(this.logout$))
          .subscribe((data) => {
            this.currentUserData = data;
          });
      } else {
        this.currentUserData = null;
      }
    });
  }

  //#region ************** SENTRY Set up *******************
  setScope(id, user, email, role) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: id,
        username: user,
        email: email,
      });
      scope.setTag('role', role);
    });
  }

  //#endregion *********************************************

  //#region ************* LOGIN / RELOGIN / REGISTER ******************
  async login({ email, password }) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      console.debug('[authService] Login successful, setting permissions');
      console.debug('What is userCredential', userCredential);
      const user = userCredential.user;
      await this.setUserValues(user);
      await this.pref.setKey('email', email);
      await this.pref.setKey('password', password);

      return user;
    } catch (error) {
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode == 'auth/too-many-requests') {
        const head = 'Too Many Requests';
        const msg =
          'Access to this account has been temporarily disabled due to many failed login attempts. Please try again later.';
        this.alert.presentAlert(head, '', msg);
      }
      console.debug('[authService] Returned error: ', errorCode, errorMessage);
    }
  }

  async reLogin(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      console.debug('[authService] reLogin successful, setting permissions');
      const user = userCredential.user;
      await this.setUserValues(user);
      await this.pref.setKey('email', email);
      await this.pref.setKey('password', password);

      return user;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.debug(
        '[authService] Reauthorization Returned error: ',
        errorCode,
        errorMessage
      );
    }
  }

  async register({ email, password, displayName, userType }) {
    try {
      console.warn({ email, password, displayName, userType });
      const transform = this.titleCase.transform(displayName);
      const names = transform.split(' ');
      const firstName = names[0];
      const lastName = names[1];
      //const email = credentials.email;
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      const userDoc = doc(this.firestore, `users/${user.user.uid}`);
      await setDoc(userDoc, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        display: transform,
        role: 'USER',
        inspector: false,
        clientChatId: '',
        permissions: {
          pushNotifications: false,
          emailNotifications: false,
        },
        chats: [],
        accountType: userType,
      });
      return user;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async registerClient({ email, displayName, clientId }) {
    try {
      console.warn({ email, displayName, clientId });
      const pwKey = await this.getDefaultPw();
      const transform = this.titleCase.transform(displayName);
      const names = transform.split(' ');
      const firstName = names[0];
      const lastName = names[1];
      const accountType = 'client';
      //const email = credentials.email;
      const user = await createUserWithEmailAndPassword(
        this.auth,
        email,
        pwKey
      );
      const userDoc = doc(this.firestore, `users/${user.user.uid}`);
      await setDoc(userDoc, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        display: transform,
        role: 'CLIENT',
        inspector: false,
        permissions: {
          pushNotifications: false,
          emailNotifications: false,
        },
        chats: [],
        clientId: clientId,
        clientChatId: '',
        accountType: accountType,
      });

      const updateDisplay = this.updateDisplayNameClient(user, displayName);

      return user;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async registerClientFromMobileApp(data, password: string) {
    try {
      //  const pwKey = await this.getDefaultPw();
      const accountType = 'client';
      //const email = credentials.email;
      const user = await createUserWithEmailAndPassword(
        this.auth,
        data.email,
        password
      );
      const userDoc = doc(this.firestore, `users/${user.user.uid}`);
      await setDoc(userDoc, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        display: data.fullName,
        role: 'CLIENT',
        inspector: false,
        permissions: {
          pushNotifications: false,
          emailNotifications: false,
        },
        chats: [],
        clientChatId: '',
        clientId: data.id,
        accountType: accountType,
      });

      const updateDisplay = this.updateDisplayNameClient(user, data.fullName);
      this.clientSvc.updateClientEmail(data.id, data.email);
      this.clientSvc.updateClientMobileAppStatus(data.id, true);

      return user;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async checkForEmail() {
    const email = await this.pref.getKey('email');
    console.log('check for email called, returned :', email);

    if (email) {
      return email;
    } else {
      return null;
    }
  }

  anonymousLogin() {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log('anon login');
        const id = this.getUserId();
        console.log('userId', id);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    if (auth) {
      return true;
    } else {
      return false;
    }
  }

  async linkAnonymousToNewAccount(data) {
    const credential = EmailAuthProvider.credential(data.email, data.password);
    const auth = getAuth();
    linkWithCredential(auth.currentUser, credential)
      .then(async (usercred) => {
        const user = usercred.user;
        const accountType = 'client';
        console.log('Anonymous account successfully upgraded', user);
        const userDoc = doc(this.firestore, `users/${user.uid}`);
        await setDoc(userDoc, {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          display: data.fullName,
          role: 'CLIENT',
          inspector: false,
          permissions: [],
          chats: [],
          clientId: data.id,
          accountType: accountType,
        });
        const updateDisplay = this.updateDisplayNameClient(
          usercred,
          data.fullName
        );
      })
      .catch((error) => {
        console.log('Error upgrading anonymous account', error);
      });
  }

  //#endregion *********************************************

  // async setUserValues(user) {
  //   this.localData.userLoggedIn.id = user.uid;
  //   this.localData.userLoggedIn.displayName = user.displayName;
  //   this.localData.userLoggedIn.email = user.email;
  //   this.localData.captainName.displayName = user.displayName;
  //   console.log('[auth] setUserValues - DisplayName set to', user.displayName);
  //   const i = await this.userSvc.isUserInspector(user.uid);
  //   if (i) {
  //     this.localData.userLoggedIn.badge = 'Captain';
  //   } else {
  //     this.localData.userLoggedIn.badge = 'Client';
  //   }

  //   if (await this.userSvc.isUserDeveloper(user.uid)) {
  //     this.localData.isDeveloper = true;
  //     console.warn('USER IS DEVELOPER');
  //   }

  //   if (await this.isUserAdmin(user.uid)) {
  //     this.localData.isAdmin = true;
  //     const role = this.currentUser.next({
  //       name: user.displayName,
  //       roles: ['admin', 'user'],
  //       authStatus: true,
  //     });
  //     this.setScope(user.uid, user.displayName, user.email, role);
  //     console.debug('User is ADMIN');
  //   } else if (await this.isUserClient(user)) {
  //     const role = this.currentUser.next({
  //       name: user.displayName,
  //       roles: ['client'],
  //       authStatus: true,
  //     });
  //     this.setScope(user.uid, user.displayName, user.email, role);
  //     await this.clientSvc.whatIsClientId();
  //     console.debug('User is Client');
  //     this.localData.userLoggedIn.badge = 'Client';
  //   } else if (await this.isUserTech(user)) {
  //     const role = this.currentUser.next({
  //       name: user.displayName,
  //       roles: ['tech'],
  //       authStatus: true,
  //     });
  //     this.setScope(user.uid, user.displayName, user.email, role);
  //     //await this.clientSvc.whatIsClientId();
  //     console.debug('User is Tech');
  //     this.localData.userLoggedIn.badge = 'Tech';
  //   } else if (await this.isUserMate(user)) {
  //     const role = this.currentUser.next({
  //       name: user.displayName,
  //       roles: ['mate'],
  //       authStatus: true,
  //     });
  //     this.setScope(user.uid, user.displayName, user.email, role);
  //     //await this.clientSvc.whatIsClientId();
  //     console.debug('User is Tech');
  //   }  
  //   else {
  //     const role = this.currentUser.next({
  //       name: user.displayName,
  //       roles: ['user'],
  //       authStatus: true,
  //     });
  //     this.setScope(user.uid, user.displayName, user.email, role);
  //     console.debug('User is User');
  //   }
  //   console.debug('[authService] setUserValues has completed');
  // }

  async setUserValues(user) {
    // Set basic user information
    this.localData.userLoggedIn.id = user.uid;
    this.localData.userLoggedIn.displayName = user.displayName;
    this.localData.userLoggedIn.email = user.email;
    this.localData.captainName.displayName = user.displayName;
    console.log('[auth] setUserValues - DisplayName set to', user.displayName);
  
    // Determine user role
    let role;
    if (await this.isUserAdmin(user.uid)) {
      role = { name: user.displayName, roles: ['admin', 'user'], authStatus: true };
      this.localData.isAdmin = true;
      console.debug('User is ADMIN');
    } else if (await this.isUserClient(user)) {
      role = { name: user.displayName, roles: ['client'], authStatus: true };
      console.debug('User is Client');
      this.localData.userLoggedIn.badge = 'Client';
      await this.clientSvc.whatIsClientId();
    } else if (await this.isUserTech(user)) {
      role = { name: user.displayName, roles: ['tech'], authStatus: true };
      console.debug('User is Tech');
      this.localData.userLoggedIn.badge = 'Tech';
    } else if (await this.isUserMate(user)) {
      role = { name: user.displayName, roles: ['mate'], authStatus: true };
      console.debug('User is Mate');
    } else {
      role = { name: user.displayName, roles: ['user'], authStatus: true };
      console.debug('User is User');
    }
  
    // Set user role and scope
    const currentUser = this.currentUser.next(role);
    this.setScope(user.uid, user.displayName, user.email, currentUser);
  
    // Check if user is inspector and developer
    const isInspector = await this.userSvc.isUserInspector(user.uid);
    if (isInspector) {
      this.localData.userLoggedIn.badge = 'Captain';
    }
  
    if (await this.userSvc.isUserDeveloper(user.uid)) {
      this.localData.isDeveloper = true;
      console.warn('USER IS DEVELOPER');
    }
  
    console.debug('[authService] setUserValues has completed');
  }
  

  async getDefaultPw() {
    const docRef = doc(this.firestore, 'apiKey', 'pwKey');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      const data = docSnap.data();
      return data.key;
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document! getDefaultPw');
    }
  }

  getUserSubject() {
    return this.currentUser.asObservable();
  }

  async isUserAdmin(userId) {
    const ref = doc(this.firestore, 'users', `${userId}`);
    console.log('[authService] Checking permissions for', userId);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.role === 'ADMIN') {
        return true;
      } else {
        // doc.data() will be undefined in this case
        console.debug('[authService] User is not admin');
        return false;
      }
    }
  }

  async canUserAccess(userId) {
    console.log('[authService] Checking permissions for', userId.displayName);
    if (!userId) {
      return false;
    }

    const ref = doc(this.firestore, 'users', `${userId.uid}`);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return false;
    }

    const data = docSnap.data();
    if (data.role === 'USER' || data.role === 'ADMIN' || data.role === 'MATE' || data.role === 'TECH') {
      console.debug('This user has a data role of :', data.role);
      return true;
    } else {
      console.debug('[authService] User cannot access');
      return false;
    }
  }

  async isUserClient(user) {
    //console.log('whats is user', user);
    const ref = doc(this.firestore, 'users', `${user.uid}`);
    console.log('[authService] Checking isUserClient for', user.displayName);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      console.debug('[authService] isUserClient User document does not exist');
      return false;
    }

    const data = docSnap.data();
    console.log(data);
    if (data.role === 'CLIENT') {
      this.localData.userLoggedIn.id = user.uid;
      this.localData.userLoggedIn.badge = 'Client';
      return true;
    } else {
      console.debug('[authService] User is not CLIENT');
      return false;
    }
  }

  async isUserTech(user) {
    //console.log('whats is user', user);
    const ref = doc(this.firestore, 'users', `${user.uid}`);
    console.log('[authService] Checking isUserTech for', user.displayName);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      console.debug('[authService] isUserTech User document does not exist');
      return false;
    }

    const data = docSnap.data();
    console.log(data);
    if (data.role === 'TECH') {
      this.localData.userLoggedIn.id = user.uid;
      this.localData.userLoggedIn.badge = 'Tech';
      return true;
    } else {
      console.debug('[authService] User is not TECH');
      return false;
    }
  }

  async isAppClient(user) {
    //console.log('whats is user', user);
    const ref = doc(this.firestore, 'users', `${user}`);
    console.log('[authService] Checking isUserClient for', user);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      console.debug('[authService] isUserClient User document does not exist');
      return false;
    }

    const data = docSnap.data();
    console.log(data);
    if (data.role === 'CLIENT') {
      return true;
    } else {
      console.debug('[authService] User is not CLIENT');
      return false;
    }
  }
  async isUserMate(user) {
    console.log('[authService] Checking isUserMate for', user.displayName);
    const ref = doc(this.firestore, 'users', `${user.uid}`);

    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      console.debug('[authService] isUserMate User document does not exist');
      return false;
    }

    const data = docSnap.data();
    console.log(data);
    if (data.role === 'MATE') {
      this.localData.userLoggedIn.id = user.uid;
      this.localData.userLoggedIn.badge = 'Mate';
      return true;
    } else {
      console.debug('[authService] User is not MATE');
      return false;
    }
  }

  hasRoles(roles: string[]): boolean {
    //  console.warn('ROLES', roles);
    //  console.warn(this.currentUser.value);
    for (const oneRole of roles) {
      if (
        !this.currentUser.value ||
        !this.currentUser.value.roles.includes(oneRole)
      ) {
        return false;
      }
    }
    return true;
  }

  async updateDisplayName(displayName: any) {
    const loading = await this.loadingController.create({
      message: 'Updating display name...',
    });
    await loading.present();
    const auth = getAuth();
    updateProfile(auth.currentUser, { displayName: displayName })
      .then(() => {
        // Profile updated!
        console.log('reg Current User', auth.currentUser);

        loading.dismiss();
        this.alert.presentAlert(
          'Success',
          'Display Name Updated',
          'Your display name has been successfully updated in the database'
        );

        console.debug('User Display Name Updated');
      })
      .catch((error) => {
        console.debug(error);
        // An error occurred

        this.alert.presentAlert(
          'Service Failure',
          'Display Name not Updated',
          'ERROR: ' + error
        );
      });
  }

  async updateDisplayNameClient(user, displayName: any) {
    const loading = await this.loadingController.create({
      message: 'Updating display name...',
    });
    await loading.present();
    updateProfile(user.user, { displayName: displayName })
      .then(() => {
        console.debug('User Display Name Updated', displayName);
        loading.dismiss();
      })
      .catch((error) => {
        console.debug(error);
        // An error occurred
        loading.dismiss();
        this.alert.presentAlert(
          'Service Failure',
          'Display Name not Updated',
          'ERROR: ' + error
        );
      });
  }

  async sendPasswordReset(): Promise<void> {
    try {
      const auth = getAuth();

      await sendPasswordResetEmail(auth, auth.currentUser.email);
      console.info('Password Reset has been successfully sent');
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      this.alert.presentAlert(
        'Error',
        'Password Reset Error',
        'ERROR CODE: ' + errorCode + ' : MESSAGE: ' + errorMessage
      );
    }
  }

  async sendPwReset(email: string): Promise<void> {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      this.alert.presentAlert(
        'Password Reset',
        'Please check SPAM folder',
        `An email has been sent to ${email}. Please check email and follow the link to reset your password.`
      );
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      this.alert.presentAlert(
        'Error',
        'Password Reset Error',
        `ERROR CODE: ${errorCode} : MESSAGE: ${errorMessage}`
      );
    }
  }

  async getCurrentUser() {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // ...

      this.localData.captainName.displayName = user.displayName;
      this.localData.userProfile.uid = user.uid;
      this.localData.userProfile.displayName = user.displayName;
      this.localData.userProfile.email = user.email;

      return true;
    } else {
      // No user is signed in.
      console.debug('no one is logged in so we sign out');
      await this.logout();
      this.route.navigate(['login']);
      await this.alert.presentAlert(
        'Token Expired',
        'Unable to Authenticate',
        'Unable to re-authenticate session. You must relog in again'
      );
      return false;
    }
  }

  getUserId() {
    const auth = getAuth();
    if (!auth) {
      throw new Error('Authentication object is null or undefined');
    }
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User is not authenticated or user object is null');
    }
    return user.uid;
  }
  

  getUserEmail() {
    const auth = getAuth();
    const user = auth.currentUser;
    return user.email;
  }

  async getUserDisplayName() {
    const auth = getAuth();
    const user = auth.currentUser;
    return user.displayName;
  }

  async logout() {
    await this.pref.removeKey('password');
    // this.currentUser.next(null);
    this.logout$.next(true);
    this.logout$.complete();
    await signOut(this.auth);

    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate(['']);
      window.location.reload();
    });
  }

  async deleteAccount() {
    const auth = getAuth();
    const user = auth.currentUser;

    deleteUser(user)
      .then(() => {
        // User deleted.
        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
     //     this.route.navigate(['']);
      //    window.location.reload();
          this.alert.accountDeleted();
          
        });
      })
      .catch((error) => {
        // An error ocurred
        // ...
        console.log('Delete Error', error);
      });
  }
}
