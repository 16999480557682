import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { EngineCheck, Checklist, SelectedClient } from 'src/app/interfaces/interface';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private refresh = new BehaviorSubject<boolean>(false);
  refreshUsers = this.refresh.asObservable();

  private unreadMsg = new BehaviorSubject<boolean>(false);
  unreadMsg$ = this.unreadMsg.asObservable();

  chatUsers = [];
  constructor() {}

  //#region  Handle Chat Users 
  public userListener(bool: boolean) {
    this.refresh.next(bool);
  }

  setChatUsers(data) {
    this.chatUsers.push(data);
    this.userListener(true);
  }

  getChatUsers() {
    return this.chatUsers;
  }

  resetChatUsers() {
    this.chatUsers = [];
  }

  //#endregion /////////////////////

  //#region  Handle Unread Chat Messages

  newUnreadMsg(bool: boolean) {
    this.unreadMsg.next(bool);
  }

  unreadMessageInfo(count: number) {
    this.unreadMsgNotifcations = count;
    this.newUnreadMsg(true);
  }

  
  unreadMsgNotifcations = 0;

  //#endregion ////////////////////////

  public getEnginePort(): EngineCheck[] {
    return this.enginePort;
  }

  public getEngineStar(): EngineCheck[] {
    return this.engineStarboard;
  }

  public getEngineMain(): EngineCheck[] {
    return this.engineMain;
  }

  public getBilge(): Checklist[] {
    return this.bilgeData;
  }

  public getGenerator(): Checklist[] {
    return this.generatorData;
  }

  public getHvacData(): Checklist[] {
    return this.hvacData;
  }

  public getMisc(): Checklist[] {
    return this.miscData;
  }

  public getMonths() {
    return this.months;
  }

  public addLog(log) {
    //console.warn(log);
    this.capturedLogs.push(log);
  }

  public clearLog() {
    this.capturedLogs = [];
  }

  public getLogLength() {
    const number = this.capturedLogs.length;
    return number;
  }

  capturedLogs = [];


  pushNotifications = false;
  emailNotifications = false;

  enginePort: EngineCheck[] = [
    { id: 0, label: 'Oil', isChecked: false },
    { id: 1, label: 'Coolant', isChecked: false },
    { id: 2, label: 'Transmission Fluid', isChecked: false },
    { id: 3, label: 'Hoses', isChecked: false },
    { id: 4, label: 'Belts', isChecked: false },
    { id: 5, label: 'Batteries', isChecked: false },
    { id: 6, label: 'Seacocks', isChecked: false },
    { id: 7, label: 'Strainers', isChecked: false },
    { id: 8, label: 'Alternator', isChecked: false },
    { id: 9, label: 'Leaks', isChecked: false },
    { id: 10, label: 'Test Run', isChecked: false },
  ];

  engineStarboard: EngineCheck[] = [
    { id: 0, label: 'Oil', isChecked: false },
    { id: 1, label: 'Coolant', isChecked: false },
    { id: 2, label: 'Transmission Fluid', isChecked: false },
    { id: 3, label: 'Hoses', isChecked: false },
    { id: 4, label: 'Belts', isChecked: false },
    { id: 5, label: 'Batteries', isChecked: false },
    { id: 6, label: 'Seacocks', isChecked: false },
    { id: 7, label: 'Strainers', isChecked: false },
    { id: 8, label: 'Alternator', isChecked: false },
    { id: 9, label: 'Leaks', isChecked: false },
    { id: 10, label: 'Test Run', isChecked: false },
  ];

  engineMain: EngineCheck[] = [
    { id: 0, label: 'Oil', isChecked: false },
    { id: 1, label: 'Coolant', isChecked: false },
    { id: 2, label: 'Transmission Fluid', isChecked: false },
    { id: 3, label: 'Hoses', isChecked: false },
    { id: 4, label: 'Belts', isChecked: false },
    { id: 5, label: 'Batteries', isChecked: false },
    { id: 6, label: 'Seacocks', isChecked: false },
    { id: 7, label: 'Strainers', isChecked: false },
    { id: 8, label: 'Alternator', isChecked: false },
    { id: 9, label: 'Leaks', isChecked: false },
    { id: 10, label: 'Test Run', isChecked: false },
  ];

  bilgeData: Checklist[] = [
    { id: 0, label: 'Forward Pump(s)', isChecked: false },
    { id: 1, label: 'Mid Pump(s)', isChecked: false },
    { id: 2, label: 'Aft Pump(s)', isChecked: false },
    { id: 3, label: 'Lights', isChecked: false },
    { id: 4, label: 'Shower Sump', isChecked: false },
    { id: 5, label: 'Blowers', isChecked: false },
    { id: 6, label: 'Cleanliness', isChecked: false },
    { id: 7, label: 'Converters', isChecked: false },
  ];

  generatorData: Checklist[] = [
    { id: 0, label: 'Oil', isChecked: false },
    { id: 1, label: 'Coolant', isChecked: false },
    { id: 2, label: 'Hoses', isChecked: false },
    { id: 3, label: 'Belts', isChecked: false },
    { id: 4, label: 'Batteries', isChecked: false },
    { id: 5, label: 'Seacocks', isChecked: false },
    { id: 6, label: 'Strainers', isChecked: false },
  ];

  hvacData: Checklist[] = [
    { id: 0, label: 'Pumps', isChecked: false },
    { id: 1, label: 'Seacock(s)', isChecked: false },
    { id: 2, label: 'Strainer(s)', isChecked: false },
    { id: 3, label: 'Return Filters', isChecked: false },
  ];

  miscData: Checklist[] = [
    {
      id: 0,
      label: 'Thrusters',
      isChecked: false,
      notAvail: true,
      checked: false,
    },
    { id: 1, label: 'Horn', isChecked: false },
    { id: 2, label: 'Nav Lights', isChecked: false },
    { id: 3, label: 'Anchor Light', isChecked: false },
    { id: 4, label: 'VHF', isChecked: false },
    { id: 5, label: 'Spot Light', isChecked: false },
    { id: 6, label: 'Strainers', isChecked: false },
    { id: 7, label: 'Water Tank Filled', isChecked: false, notAvail: true, checked: false,},
  ];

  public months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public monthsYear = [
    'January 2022',
    'February 2022',
    'March 2022',
    'April 2022',
    'May 2022',
    'June 2022',
    'July 2022',
    'August 2022',
    'September 2022',
    'October 2022',
    'November 2022',
    'December 2022',
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023',
    'January 2024',
    'February 2024',
    'March 2024',
    'April 2024',
    'May 2024',
    'June 2024',
    'July 2024',
    'August 2024',
    'September 2024',
    'October 2024',
    'November 2024',
    'December 2024',
    'January 2025',
    'February 2025',
    'March 2025',
    'April 2025',
    'May 2025',
    'June 2025',
    'July 2025',
    'August 2025',
    'September 2025',
    'October 2025',
    'November 2025',
    'December 2025',
    'January 2026',
    'February 2026',
    'March 2026',
    'April 2026',
    'May 2026',
    'June 2026',
    'July 2026',
    'August 2026',
    'September 2026',
    'October 2026',
    'November 2026',
    'December 2026',
  ];

  selections: SelectedClient = {
    id: '',
    clientStatus: '',
    fName: '',
    lName: '',
    fullName: '',
    engineCount: '',
    boatName: '',
    boatImg: '',
    lastInspDate: '',
    inspFileId: '',
    wakeboard: false,
    imageUrl: '',
    location: '',
    marina: '',
  };

  weather = {
    temp: '',
    high: '',
    low: '',
    humidity: '',
    feels_like: '',
    description: '',
    icon: '',
    main: '',
    windSpeed: '',
    gust: '',
    windDirection: '',
    visibility: '',
  };

  serviceLevels = [
    'admirals',
    'captains',
    'wake',
    'basic'
  ]

  gpsId = { id: '' };

  boatFolder = { boatName: '' };

  canView = { view: false };

  public captainName = { displayName: '' };

  clientId = { id: '' };

  clientInspection = { date: '1', fileId: '', url: '' };

  engineHours = { port: 0, star: 0, main: 0, generator: 0 };

  fromWhereIcame = { from: '' };

  isDataAlreadyThere = { answer: false };

  pdfReportData = { filePath: '' };

  reportComments = { comments: ' ' };

  tempBoatUpload = { data: '', clientId: '', boatName: '' };

  photoSet = { value: false };

  userProfile = { uid: '', displayName: '', email: '' };

  vessel = { value: '' };

  detailClientId = { id: '' };

  storageUrl = { url: '' };

  pastDue = [];

  tempWx = {};

  //inspectionList = [];

  toAssignList = [];

  reportId = { id: '' };

  isDeveloper = false;

  isAdmin = false;

  currentReportDate: number;

  public cloudFiles = [];
  public clientList = [];
  public customer = [{ value: '' }];

  // public clientLast = [{ value: '' }];

  //public boatImg = [{ value: '', isNull: true }];

  //public engineCount = [{ value: '' }];

  //public dateText = [{ value: '' }];

  public subFolderData = [{ filePath: '' }];

  // public chatUsers = [];

  //public detailClientId = [];

  //public wakeboardBoat = [{ canView: false }];

  userLoggedIn = {
    id: '',
    displayName: '',
    email: '',
    badge: '',
    clientId: '',
  };


  appVersion: string;

  currentChatId: string;

  public photos: string;

  boatManufacturers = [
    // A
    "Arno Leopard",
    "Azimut",
  
    // B
    "Bayliner",
    "Belize",
    "Beneteau",
    "Bestway",
    "Black Thunder",
    "Bluewater Yachts",
    "Broward",
  
    // C
    "CL Yachts",
    "Californian",
    "Carver",
    "Chaparral",
    "Chris-Craft",
    "Coastal Craft",
    "Cobalt",
    "Crownline",
    "Cruisers",
    "Cruisers Yachts",
    "Custom",
  
    // D
    "DeFever",
    "Delta Powerboats",
    "Donzi",
    "Doral",
    "Dyna",
  
    // E
    "Eastern",
    "Evo Yachts",
  
    // F
    "Formula",
    "Four Winns",
  
    // G
    "Galeon",
    "Gibson",
    "Grand Banks",
    "Gulfstar",
  
    // H
    "Hanover",
    "Harbor Master",
    "Hartman-Palmer",
    "Hatteras",
    "Hinckley",
    "Home Built",
    "Horizon",
    "Hunt Yachts",
    "Hyatt",
  
    // I
    "IMP",
    "Intrepid",
    "Island Gypsy",
  
    // J
    "Jeanneau",
    "Jefferson",
  
    // L
    "Larson",
    "Lazzara",
    "Lindell",
  
    // M
    "MJM",
    "Mainship",
    "Mariah",
    "Marine Trader",
    "Maritimo",
    "Marlow",
    "Marquis",
    "Matthews",
    "Maxum",
    "Mercury",
    "Meridian",
  
    // N
    "Neptunus",
    "Nor-Tech",
    "Nordhavn",
    "Novielli",
  
    // O
    "Ocean Alexander",
    "Offshore Yachts",
    "Outback Yachts",
    "Outer Reef Trident",
    "Outer Reef Yachts",
  
    // P
    "Palm Beach Motor Yachts",
    "President",
    "Prestige",
  
    // R
    "Regal",
    "Rinker",
    "Riviera",
    "Rodriquez",
  
    // S
    "Sabre",
    "Schaefer",
    "Sea Ray",
    "Silverton",
    "Striker",
    "Sunseeker",
    "Symbol",
  
    // T
    "Tiara Yachts",
    "Trojan",
  
    // W
    "Wellcraft",
    "Windy",
  
    // Z
    "Zeelander",
  ];
  
  seaRayModels = [
    { make: 'Sea Ray', model: '200' },
    { make: 'Sea Ray', model: '200 OV' },
    { make: 'Sea Ray', model: '215 Express Cruiser' },
    { make: 'Sea Ray', model: '215 Weekender' },
    { make: 'Sea Ray', model: '225 Weekender' },
    { make: 'Sea Ray', model: '230 Cuddy Cabin' },
    { make: 'Sea Ray', model: '230 Sundancer' },
    { make: 'Sea Ray', model: '230 Weekender' },
    { make: 'Sea Ray', model: '240 Sundancer' },
    { make: 'Sea Ray', model: '245 Sundancer' },
    { make: 'Sea Ray', model: '250' },
    { make: 'Sea Ray', model: '250 Sundancer' },
    { make: 'Sea Ray', model: '255 Sedan Bridge' },
    { make: 'Sea Ray', model: '255 Sundancer' },
    { make: 'Sea Ray', model: '260 Sundancer' },
    { make: 'Sea Ray', model: '265 Sundancer' },
    { make: 'Sea Ray', model: '268 Sundancer' },
    { make: 'Sea Ray', model: '270' },
    { make: 'Sea Ray', model: '270 Sundancer' },
    { make: 'Sea Ray', model: '270 Sundancer Special Edition' },
    { make: 'Sea Ray', model: '275 Sundancer' },
    { make: 'Sea Ray', model: '28 sundancer' },
    { make: 'Sea Ray', model: '280 Sun Sport' },
    { make: 'Sea Ray', model: '280 Sundancer' },
    { make: 'Sea Ray', model: '280 Sundancer 315' },
    { make: 'Sea Ray', model: '290 Amberjack' },
    { make: 'Sea Ray', model: '290 SS' },
    { make: 'Sea Ray', model: '290 Sun Sport' },
    { make: 'Sea Ray', model: '290 Sundancer' },
    { make: 'Sea Ray', model: '295' },
    { make: 'Sea Ray', model: '30 Sportbridge' },
    { make: 'Sea Ray', model: '300 Srv' },
    { make: 'Sea Ray', model: '300 Sundancer' },
    { make: 'Sea Ray', model: '300 Sundeck' },
    { make: 'Sea Ray', model: '300 Weekender' },
    { make: 'Sea Ray', model: '305 Sedan Bridge' },
    { make: 'Sea Ray', model: '305 Sundancer HT' },
    { make: 'Sea Ray', model: '305 Sundancer HT DIESEL' },
    { make: 'Sea Ray', model: '310 Express Cruiser' },
    { make: 'Sea Ray', model: '310 SLX' },
    { make: 'Sea Ray', model: '310 Sport Bridge' },
    { make: 'Sea Ray', model: '310 Sundancer' },
    { make: 'Sea Ray', model: '315 Sundancer' },
    { make: 'Sea Ray', model: '320 DAE Sundancer' },
    { make: 'Sea Ray', model: '320 DAE Sundancer Coupé REDUZIERT' },
    { make: 'Sea Ray', model: '320 Sea Ray' },
    { make: 'Sea Ray', model: '320 Sundancer' },
    { make: 'Sea Ray', model: '320 Sundancer Inboard' },
    { make: 'Sea Ray', model: '325 DA' },
    { make: 'Sea Ray', model: '325 SUNDANCER' },
    { make: 'Sea Ray', model: '33 Express' },
    { make: 'Sea Ray', model: '330 Express Cruiser' },
    { make: 'Sea Ray', model: '330 Sundancer' },
    { make: 'Sea Ray', model: '335 SUNDANCER REDUZIERT' },
    { make: 'Sea Ray', model: '335 Sundancer' },
    { make: 'Sea Ray', model: '34' },
    { make: 'Sea Ray', model: '340 Convertible' },
    { make: 'Sea Ray', model: '340 DA' },
    { make: 'Sea Ray', model: '340 Express Cruiser' },
    { make: 'Sea Ray', model: '340 Sea Ray' },
    { make: 'Sea Ray', model: '340 Sundancer' },
    { make: 'Sea Ray', model: '345 Sedan Bridge' },
    { make: 'Sea Ray', model: '350' },
    { make: 'Sea Ray', model: '350 Sundancer' },
    { make: 'Sea Ray', model: '355 Sundancer' },
    { make: 'Sea Ray', model: '36 Sedan Bridge' },
    { make: 'Sea Ray', model: '360 Aft Cabin' },
    { make: 'Sea Ray', model: '360 Sundancer' },
    { make: 'Sea Ray', model: '365 DA' },
    { make: 'Sea Ray', model: '37' },
    { make: 'Sea Ray', model: '370 Aft Cabin' },
    { make: 'Sea Ray', model: '370 DB Sedan Bridge' },
    { make: 'Sea Ray', model: '370 Express Cruiser' },
    { make: 'Sea Ray', model: '370 Outboard' },
    { make: 'Sea Ray', model: '370 Sea Ray' },
    { make: 'Sea Ray', model: '370 Sedan Bridge' },
    { make: 'Sea Ray', model: '370 Sundancer' },
    { make: 'Sea Ray', model: '370 Venture' },
    { make: 'Sea Ray', model: '375 DA' },
    { make: 'Sea Ray', model: '375 DA Sundancer' },
    { make: 'Sea Ray', model: '375 Sundancer Twin Diesel' },
    { make: 'Sea Ray', model: '38 Sundancer' },
    { make: 'Sea Ray', model: '380 Aft Cabin' },
    { make: 'Sea Ray', model: '380 Sundancer' },
    { make: 'Sea Ray', model: '380 Sunsport' },
    { make: 'Sea Ray', model: '380AC' },
    { make: 'Sea Ray', model: '390 Express Cruiser' },
    { make: 'Sea Ray', model: '390 Motor Yacht' },
    { make: 'Sea Ray', model: '390 Sea Ray' },
    { make: 'Sea Ray', model: '390 Sedan Bridge' },
    { make: 'Sea Ray', model: '390 Sundancer' },
    { make: 'Sea Ray', model: '395 Sundancer' },
    { make: 'Sea Ray', model: '40 Motor Yacht' },
    { make: 'Sea Ray', model: '40 Sundancer' },
    { make: 'Sea Ray', model: '400 DB' },
    { make: 'Sea Ray', model: '400 Express Cruiser' },
    { make: 'Sea Ray', model: '400 Sedan Bridge' },
    { make: 'Sea Ray', model: '400 Sundancer' },
    { make: 'Sea Ray', model: '410 Express Cruiser' },
    { make: 'Sea Ray', model: '410 Sundancer' },
    { make: 'Sea Ray', model: '415' },
    { make: 'Sea Ray', model: '415 Aft Cabin' },
    { make: 'Sea Ray', model: '420 Aft Cabin' },
    { make: 'Sea Ray', model: '420 Sedan Bridge' },
    { make: 'Sea Ray', model: '420 Sundancer' },
    { make: 'Sea Ray', model: '43 Sundancer' },
    { make: 'Sea Ray', model: '435 Sedan Bridge' },
    { make: 'Sea Ray', model: '44 Sedan Bridge' },
    { make: 'Sea Ray', model: '44 Sundancer' },
    { make: 'Sea Ray', model: '440 Aft Cabin' },
    { make: 'Sea Ray', model: '440 Express Bridge' },
    { make: 'Sea Ray', model: '440 Sedan Bridge' },
    { make: 'Sea Ray', model: '440 Sundancer' },
    { make: 'Sea Ray', model: '450 Express Bridge' },
    { make: 'Sea Ray', model: '450 Sedan Bridge' },
    { make: 'Sea Ray', model: '450 Sundancer' },
    { make: 'Sea Ray', model: '455 Sedan Bridge' },
    { make: 'Sea Ray', model: '455 Sundancer' },
    { make: 'Sea Ray', model: '460 Express Cruiser' },
    { make: 'Sea Ray', model: '460 Sundancer' },
    { make: 'Sea Ray', model: '47 Sedan Bridge' },
    { make: 'Sea Ray', model: '470 Sundancer' },
    { make: 'Sea Ray', model: '475 DA' },
    { make: 'Sea Ray', model: '475 SUNDANCER' },
    { make: 'Sea Ray', model: '48 Sedan Motor Yacht' },
    { make: 'Sea Ray', model: '48 Sundancer' },
    { make: 'Sea Ray', model: '480 Motor Yacht' },
    { make: 'Sea Ray', model: '480 Sedan Bridge' },
    { make: 'Sea Ray', model: '480 Sundancer' },
    { make: 'Sea Ray', model: '480/500 Sundancer' },
    { make: 'Sea Ray', model: '500 Sedan Bridge' },
    { make: 'Sea Ray', model: '500 Sundancer' },
    { make: 'Sea Ray', model: '51' },
    { make: 'Sea Ray', model: '510 Fly' },
    { make: 'Sea Ray', model: '510 Sundancer' },
    { make: 'Sea Ray', model: '515 Sundancer' },
    { make: 'Sea Ray', model: '52 Sedan Bridge' },
    { make: 'Sea Ray', model: '52 Sundancer' },
    { make: 'Sea Ray', model: '520 Sedan Bridge' },
    { make: 'Sea Ray', model: '525 Sundancer' },
    { make: 'Sea Ray', model: '540 Cockpit Motor Yacht' },
    { make: 'Sea Ray', model: '540 Sundancer' },
    { make: 'Sea Ray', model: '55' },
    { make: 'Sea Ray', model: '55 Sundancer' },
    { make: 'Sea Ray', model: '550 Sedan Bridge' },
    { make: 'Sea Ray', model: '550 Sundancer' },
    { make: 'Sea Ray', model: '560 SD' },
    { make: 'Sea Ray', model: '560 Sedan Bridge' },
    { make: 'Sea Ray', model: '58 Sedan Bridge' },
    { make: 'Sea Ray', model: '580 Sedan Bridge' },
    { make: 'Sea Ray', model: '580 Sundancer' },
    { make: 'Sea Ray', model: '580 Super Sun Sport' },
    { make: 'Sea Ray', model: '585 Sedan Bridge' },
    { make: 'Sea Ray', model: '60 Sundancer' },
    { make: 'Sea Ray', model: '610 Sundancer' },
    { make: 'Sea Ray', model: '630 Super Sun Sport' },
    { make: 'Sea Ray', model: '680 Sun Sport' },
    { make: 'Sea Ray', model: '680SS' },
  ];
  
  
}
