import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { addDoc, collection, collectionData, deleteDoc, doc, Firestore, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Assigned, Captain, ChatNotify, SendMessage } from 'src/app/interfaces/schedule';
import { Logger } from '../../logs/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  logout$: Subject<boolean> = new Subject<boolean>();
  constructor(private firestore: Firestore, private auth: Auth, private logger: Logger) {
    onAuthStateChanged(this.auth, (user) => {
      if (!user) {
        this.logout$.next(true);
      }
    });
  }

  getAssignedList(): Observable<Assigned[]> {
    const ref = query(collection(this.firestore, 'assigned'));
    return collectionData(ref, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as Observable<Assigned[]>;
  }

  async getAssignedListById(id: string) {
   // console.log('getAssignedListById called for ', id);
    const ref = doc(this.firestore, `assigned/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const result = docSnap.data();
      return result.assignedTo;
    } else {
      return null;
    }
  }

  addBoatToAssignedList(data: Assigned) {
    const notesRef = doc(this.firestore, `assigned/${data.id}`);
    return setDoc(notesRef, data, { merge: true });
  }

  deleteBoatFromList(data: Assigned) {
    console.log('Delete Service recieved: ', data);
    const noteDocRef = doc(this.firestore, `assigned/${data}`);
    return deleteDoc(noteDocRef);
  }

  updateList(data: Assigned) {
    const noteDocRef = doc(this.firestore, `assigned/${data.id}`);
    return updateDoc(noteDocRef, {
      boat: data.boat,
      assignedTo: data.assignedTo,
    });
  }

  addNotified(id) {
    const today = new Date();
    const ref = doc(this.firestore, `assigned/${id}`);
    return updateDoc(ref, { 
      overDueNotificationSent: today.toLocaleDateString(),
    });
  }

  async didNotifyAlreadyCheck(id) {
  //  console.log('didNotifyAlreadyCheck called for ', id);
    const ref = doc(this.firestore, `assigned/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const result = docSnap.data();
      if(result.overDueNotificationSent && result.overDueNotificationSent != '') {
        return result.overDueNotificationSent;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  async checkForDueDate(id) {
  //  console.log('checkForDueDate called for ', id);
    const ref = doc(this.firestore, `assigned/${id}`);
    const docSnap = await getDoc(ref);

    if (docSnap.exists()) {
      const result = docSnap.data();
      return result.dateDue;
    } else {
      return null;
    }
  }

  //#region ///////////// Send Overdue Message /////////////////

 async overDueMessage(data) {
      const ref = collection(this.firestore, 'overDueMsg');
      const result = await addDoc(ref, data);
      return result;
  }



  //#endregion ///////////////////////////////////////////////////

  //#region //////////////// Capatin List / UserId / SendMessage /////////////////
  getCaptains(): Observable<Captain[]> {
    const ref = query(collection(this.firestore, 'users'));
    return collectionData(ref, { idField: 'id' }).pipe(
      takeUntil(this.logout$)
    ) as Observable<Captain[]>;
  }

  async getCaptainsOnce() {
    let list = [];
    const ref = collection(this.firestore, 'users');
    const q = query(ref, where('inspector', '==', true), orderBy("first_name"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
      //console.log(doc.id, ' => ', doc.data());
    });
    return list;
  }

  async getUserId(myDisplay: string) {
    let result;
    const ref = collection(this.firestore, 'users');
    const q = query(ref, where('display', '==', myDisplay));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, ' => ', doc.data());
      result = doc.id;
    });
    return result;
  }

  async getFCMToken(userId: string): Promise<string | undefined> {
    const ref = doc(this.firestore, 'FCMToken', `${userId}`);
    console.log('getFCMToken called with this userID', userId);

    if (!userId) {
      console.warn('getFCMToken called with no userId');
      return undefined;
    }
    const docSnap = await getDoc(ref);
    
    if (docSnap.exists) {
      const data = docSnap.data();
      if (data && data.tokenId) {
        return data.tokenId;
      } else {
        console.warn('FCM token data is incomplete for', userId);
      }
    } else {
      console.log('No FCM token exists for', userId);
    }
    console.log('getFCMToken returning undefined');
    return undefined; // Return undefined if no FCM token found or incomplete data
  }

  async getFCMTokenByName(userName: string) {
    let result;
    const ref = collection(this.firestore, 'FCMToken');
    const q = query(ref, where("user", "==", userName));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      result = data.tokenId;
    });
    return result;
  }

  async setSendMessage(data: SendMessage) {
    const ref = collection(this.firestore, 'sendMessage');
    const result = await addDoc(ref, data);
    return result;
  }

  async sendChatNotification(data: ChatNotify) {
    console.warn('Send Chat called with this data', data);
    const ref = collection(this.firestore, 'sendChatMsg');
    const result = await addDoc(ref, data);
    return result;
  }

  async getDaysForCalculation() {
    const docRef = doc(this.firestore, 'settings', 'sjPC5xGT68CHhLfoa7RT');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const result = docSnap.data();
      //console.log('getDaysForCalculation data:', docSnap.data());
      return result.value;
    } else {
      // doc.data() will be undefined in this case
      console.log('Could not read getDaysForCalculation');
    }
  }
}
